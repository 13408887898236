<div class="current-year">
  <i class="app-icon-arrow-left" (click)="goPreviousYear()" [class.hide-arrow]="selectedYear === minYear"></i>
  <h4>{{ selectedYear }}</h4>
  <i class="app-icon-arrow-right" (click)="goNextYear()" [class.hide-arrow]="selectedYear === maxYear"></i>
</div>
<ul class="month-select" *ngIf="monthNames">
  <li
    *ngFor="let month of monthNames; trackBy: trackByItems; index as i"
    (click)="selectMonth(i)"
    [class.disabled]="isDisabled(i)">
    {{ month | translate }}
  </li>
</ul>
