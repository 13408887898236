<ng-container>
  <div class="btn-add-file" (click)="onClickAddFile()">
    <i *ngIf="showIcon" class="app-icon-add"></i>
    <span class="add-text">{{ inputMsg | translate }}</span>
  </div>
  <span class="body-files-message" *ngIf="showFooterMsg">{{ 'INFO_MESSAGE.BODY_FILES' | translate }}</span>
</ng-container>

<input
  #fileInput
  hidden
  [attr.accept]="extensions"
  type="file"
  data-cy-id="example-file"
  (change)="onChangeInputFile($event.target.files)" />
