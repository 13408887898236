import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';

import { PtrabSessionManager } from '@app/ptrab/services/session/ptrab-session.manager.service';
import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { User } from '@app/shared/models/user/user.model';
import { AppManagerService } from '@services/app/app.manager.service';
import { AuthService } from '@services/auth/auth.service';
import { AppError, ErrorTypes } from '@services/error/error.model';
import { ErrorService } from '@services/error/error.service';
import { Logger } from '@services/logger/logger.service';
import { NavigationEvent, NavigationEvents, NavigationService } from '@services/navigation/navigation.service';
import { NetworkService } from '@services/network/network.service';

import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {
  private readonly logger = new Logger('AuthGuardService');

  constructor(
    private appManagerService: AppManagerService,
    private authService: AuthService,
    private errorService: ErrorService,
    private navigationService: NavigationService,
    private network: NetworkService,
    private ptrabSessionManager: PtrabSessionManager,
    private userService: UserService
  ) {}

  async canLoad(): Promise<boolean> {
    return this.authService.isLoggedIn() || this.authService.hasActivo2Session();
  }

  async canActivate(): Promise<boolean> {
    try {
      const hasAdfsSession = await this.authService.hasSession();

      if (!hasAdfsSession) {
        const message = 'User is not logged, unable to open a private page';
        this.logger.error(message);
        this.errorService.add(
          new AppError(ErrorTypes.FORBIDDEN, message, {
            hash: location.hash
          })
        );
        return false;
      }

      this.logger.debug('Waiting appManager is ready');

      this.appManagerService.init(this.network, this.ptrabSessionManager);
      await this.appManagerService.ready();
      this.logger.debug('AppManager is ready!');

      const user: User = await this.userService.getStoredUser();
      if (!user) {
        this.logger.error('User info not in storage');
        this.errorService.add(new AppError(ErrorTypes.GETTING_USER_INFO));
        return false;
      }

      const accepted = user.acceptLegal;
      if (!accepted) {
        this.logger.debug('Cannot enter on page as user should accept terms first');
        const navEvent = new NavigationEvent(NavigationEvents.Push, { path: PAGES.TERMS_AND_CONDITIONS_INTER });
        this.navigationService.navigate(navEvent);
        return false;
      }

      this.logger.debug('Allowed to enter on page');
      return true;
    } catch (error) {
      this.logger.error('Error on canActivate:', error);
      return false;
    }
  }
}
