import { Component, Input, ViewChild } from '@angular/core';

import { MPopoverDirective } from '@mercadona/components/popover';

@Component({
  selector: 'app-notification-advice',
  templateUrl: './notification-advice.component.html',
  styleUrls: ['./notification-advice.component.scss']
})
export class NotificationAdviceComponent {
  @ViewChild('mPopover') popover!: MPopoverDirective;
  @Input() unreadNotificationsNumber!: number;

  onActionClicked() {
    this.popover.hide();
  }
}
