import { Component, Input, OnInit } from '@angular/core';

import { errorMessageData } from '@app/components/error-msg/error-msg-constants';
import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { IErrorMsg } from '@app/components/error-msg/error-msg-interface';
import { NavigationService } from '@app/services';

@Component({
  selector: 'app-error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent implements OnInit {
  @Input() type!: ErrorMsgEnum;

  errorData!: IErrorMsg;

  constructor(private navigation: NavigationService) {}

  ngOnInit() {
    this.errorData = errorMessageData[this.type];
  }

  goBack() {
    this.navigation.goBack();
  }
}
