export const ADFS_SUCCESS_REDIRECT_PARTS = ['code='];
export const ADFS_ERROR_REDIRECT_PARTS = ['error=', 'error_description=', 'state='];
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { cryptoRandom } from '@app/shared/utils/utils';

export class AuthenticationHelpers {
  static getQuery(hash: MSafeAny) {
    if (hash.indexOf('#/') > -1) {
      hash = hash.substring(hash.indexOf('#/') + 2);
    } else if (hash.indexOf('#') > -1) {
      hash = hash.substring(1);
    } else if (hash.indexOf('?') > -1) {
      hash = hash.substring(1);
    }

    return hash;
  }

  static deserialize(hash: MSafeAny): MSafeAny {
    const query = this.getQuery(hash);
    let match;
    const pl = /\+/g; // Regex for replacing addition symbol with a space
    const search = /([^&=]+)=([^&]*)/g;
    const decode = (s: MSafeAny) => {
      return decodeURIComponent(s.replace(pl, ' '));
    };

    const obj: { [key: string]: MSafeAny } = {};

    match = search.exec(query);

    while (match) {
      obj[decode(match[1])] = decode(match[2]);
      match = search.exec(query);
    }

    return obj;
  }

  static serialize(responseType: MSafeAny, obj: MSafeAny, resource: MSafeAny): string {
    const str: string[] = [];

    if (obj !== null) {
      str.push('?response_type=' + encodeURIComponent(responseType));
      str.push('client_id=' + encodeURIComponent(obj.clientId));
      if (resource) {
        str.push('resource=' + encodeURIComponent(resource));
      }

      str.push('redirect_uri=' + encodeURIComponent(obj.redirectUri));
      str.push('scope=' + encodeURIComponent(obj.scope));

      if (Object.prototype.hasOwnProperty.call(obj, 'slice')) {
        str.push('slice=' + encodeURIComponent(obj.slice));
      }

      if (Object.prototype.hasOwnProperty.call(obj, 'extraQueryParameter')) {
        str.push(obj.extraQueryParameter);
      }

      // const correlationId = obj.correlationId ? obj.correlationId : this.guid();
      // str.push('client-request-id=' + encodeURIComponent(correlationId));
    }

    return str.join('&');
  }

  static guid(): string {
    // RFC4122: The version 4 UUID is meant for generating UUIDs from truly-random or
    // pseudo-random numbers.
    // The algorithm is as follows:
    //     Set the two most significant bits (bits 6 and 7) of the
    //        clock_seq_hi_and_reserved to zero and one, respectively.
    //     Set the four most significant bits (bits 12 through 15) of the
    //        time_hi_and_version field to the 4-bit version number from
    //        Section 4.1.3. Version4
    //     Set all the other bits to randomly (or pseudo-randomly) chosen
    //     values.
    // UUID                   = time-low "-" time-mid
    // "-"time-high-and-version "-"clock-seq-reserved and low(2hexOctet)"-" node
    // time-low               = 4hexOctet
    // time-mid               = 2hexOctet
    // time-high-and-version  = 2hexOctet
    // clock-seq-and-reserved = hexOctet:
    // clock-seq-low          = hexOctet
    // node                   = 6hexOctet
    // Format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
    // y could be 1000, 1001, 1010, 1011 since most significant two bits needs to be 10
    // y values are 8, 9, A, B

    const cryptoObj = window.crypto || window['msCrypto']; // for IE 11
    if (cryptoObj && cryptoObj.getRandomValues) {
      const buffer = new Uint8Array(16);
      cryptoObj.getRandomValues(buffer);
      // buffer[6] and buffer[7] represents the time_hi_and_version field.
      // We will set the four most significant bits (4 through 7) of buffer[6]
      // to represent decimal number 4 (UUID version number).
      // eslint-disable-next-line no-bitwise
      buffer[6] |= 0x40; // buffer[6] | 01000000 will set the 6 bit to 1.
      // eslint-disable-next-line no-bitwise
      buffer[6] &= 0x4f; // buffer[6] & 01001111 will set the 4, 5, and 7 bit to 0 such that bits 4-7 == 0100 = "4".
      // buffer[8] represents the clock_seq_hi_and_reserved field.
      // We will set the two most significant bits (6 and 7)
      // of the clock_seq_hi_and_reserved to zero and one, respectively.
      // eslint-disable-next-line no-bitwise
      buffer[8] |= 0x80; // buffer[8] | 10000000 will set the 7 bit to 1.
      // eslint-disable-next-line no-bitwise
      buffer[8] &= 0xbf; // buffer[8] & 10111111 will set the 6 bit to 0.
      return (
        this.decimalToHex(buffer[0]) +
        this.decimalToHex(buffer[1]) +
        this.decimalToHex(buffer[2]) +
        this.decimalToHex(buffer[3]) +
        '-' +
        this.decimalToHex(buffer[4]) +
        this.decimalToHex(buffer[5]) +
        '-' +
        this.decimalToHex(buffer[6]) +
        this.decimalToHex(buffer[7]) +
        '-' +
        this.decimalToHex(buffer[8]) +
        this.decimalToHex(buffer[9]) +
        '-' +
        this.decimalToHex(buffer[10]) +
        this.decimalToHex(buffer[11]) +
        this.decimalToHex(buffer[12]) +
        this.decimalToHex(buffer[13]) +
        this.decimalToHex(buffer[14]) +
        this.decimalToHex(buffer[15])
      );
    }
    const guidHolder = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    const hex = '0123456789abcdef';
    let r = 0;
    let guidResponse = '';
    for (let i = 0; i < 36; i++) {
      if (guidHolder[i] !== '-' && guidHolder[i] !== '4') {
        // each x and y needs to be random
        // eslint-disable-next-line no-bitwise
        r = (cryptoRandom() * 16) | 0;
      }
      if (guidHolder[i] === 'x') {
        guidResponse += hex[r];
      } else if (guidHolder[i] === 'y') {
        // clock-seq-and-reserved first hex is filtered and remaining hex values are random
        // eslint-disable-next-line no-bitwise
        r &= 0x3; // bit and with 0011 to set pos 2 to zero ?0??
        // eslint-disable-next-line no-bitwise
        r |= 0x8; // set pos 3 to 1 as 1???
        guidResponse += hex[r];
      } else {
        guidResponse += guidHolder[i];
      }
    }
    return guidResponse;
  }

  private static decimalToHex(num: number): string {
    let hex: string = num.toString(16);

    while (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
  }
}
