import { Permissions } from '@app/shared/enums/permissions/permissions.enum';
import { flatten } from '@app/shared/utils/utils';

export const PTRAB_SECTIONS_PERMISSIONS: { [key: string]: Permissions[] } = {
  PAYSLIPS: [
    Permissions.PAYSLIPS,
    Permissions.CAR_TRANSFER,
    Permissions.DIRECT_DEBIT,
    Permissions.WAGE_GARNISHMENT,
    Permissions.EXEMPLOYEE_PAYSLIPS
  ],
  PERSONAL_INCOME_TAX: [
    Permissions.IRPF,
    Permissions.WITHHOLDING_CERTIFICATE,
    Permissions.VOLUNTARY_WITHHOLDING,
    Permissions.PERCENTAGE_READJUSTMENT,
    Permissions.WHAT_IS_IRPF,
    Permissions.EXEMPLOYEE_WITHHOLDING
  ],
  CERTIFICATES: [
    Permissions.REMUNERATION_CERTIFICATES,
    Permissions.PERMISSIONS_AND_LEAVES_CERTIFICATES,
    Permissions.HIRING_CERTIFICATES,
    Permissions.CONTRACT_CERTIFICATE
  ],
  PERSONAL_DATA: [Permissions.PERSONAL_DATA, Permissions.LANGUAGES]
};
export const PTRAB_PERMISSIONS = flatten(Object.values(PTRAB_SECTIONS_PERMISSIONS));
