<div class="top-content top-content--covered">
  <img class="cover-img" [hidden]="!show" (load)="show = true" [src]="text.thumbnail" />
  <div class="inner-content">
    <span class="card-title">{{ text.title | striphtml }}</span>

    <span class="card-description text-description" [innerHTML]="text.summary || (text.text | striphtml)"></span>

    <span class="card-date">
      {{ cardDate | date : 'dd/MM/yyyy' }}
    </span>
  </div>

  <app-card-tooltip *ngIf="isTypeLite"></app-card-tooltip>
  <app-share-content
    *ngIf="text.isShareable() && !isWebview"
    class="share-corner"
    [item]="text"
    [isDetail]="false"
    [analyticsCategory]="analyticsCategory"></app-share-content>
</div>

<div class="bottom-content">
  <app-card-info *ngIf="!isTypeLite" [item]="text" [cardType]="type"></app-card-info>
  <app-card-buttons
    *ngIf="hasActionButtons"
    [exampleItem]="text"
    [publishIsDisabled]="publishIsDisabled"></app-card-buttons>
</div>
