<div *ngIf="hasActions" class="actions_container" [ngClass]="actionsSpacing">
  <div
    *ngIf="item?.likes_allowed"
    class="action anim has-over"
    [ngClass]="{ active: item.liked, 'less-than-three-actions': isLessThanThree }"
    (mouseover)="over(true, iconsType.like)"
    (mouseout)="over(false, iconsType.like)">
    <input type="checkbox" class="like-btn" [(ngModel)]="item.liked" (click)="like()" />
    <img class="animated-icon action-like-icon" [src]="likeImagePath" (click)="like()" />
    <span class="action-title" (click)="like()">{{ 'LIKES.LIKE' | translate }} </span>
    <span class="shape no-clickable">
      <span *ngFor="let n of [0, 1, 2, 3, 4, 5, 6, 7]; trackBy: trackByItems"></span>
    </span>
  </div>
  <div
    *ngIf="item?.comments_allowed"
    class="action anim has-over"
    [ngClass]="{ active: item?.user_comments > 0, 'less-than-three-actions': isLessThanThree }"
    (click)="goToComments()"
    (mouseover)="over(true, iconsType.comment)"
    (mouseout)="over(false, iconsType.comment)">
    <img class="animated-icon action-comment-icon" [src]="commentImagePath" />
    <span class="action-title">{{ 'COMMENTS.COMMENT' | translate }}</span>
    <span class="shape no-clickable">
      <span *ngFor="let n of [0, 1, 2, 3, 4, 5, 6, 7]; trackBy: trackByItems"></span>
    </span>
  </div>
  <div class="share-container" *ngIf="isShareable">
    <app-share-content
      [class.less-than-three-actions]="isLessThanThree"
      [item]="item"
      [isDetail]="true"
      [shareTitle]="'SHARE.TITLE'"
      [analyticsCategory]="analyticsCategory"
      class="icon-share"></app-share-content>
  </div>
</div>
