import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalManager } from '@app/services';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { User } from '@app/shared/models/user/user.model';

export enum EditAvatarActions {
  DELETE = 'DELETE',
  SELECT = 'SELECT'
}

@Component({
  selector: 'app-edit-avatar-modal',
  templateUrl: './edit-avatar-modal.component.html',
  styleUrls: ['./edit-avatar-modal.component.scss']
})
export class EditAvatarModalComponent implements OnInit {
  user!: User;
  buttons: Buttons[] = [
    {
      text: 'PROFILE.EDIT_AVATAR.DELETE',
      type: 'secondary',
      onClick: () => this.modalManager.dismissMatModal(EditAvatarActions.DELETE)
    },
    {
      text: 'PROFILE.EDIT_AVATAR.SELECT_PHOTO',
      type: 'primary',
      enabled: true,
      onClick: () => this.modalManager.dismissMatModal(EditAvatarActions.SELECT)
    }
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: MSafeAny, private modalManager: ModalManager) {}

  ngOnInit() {
    this.user = this.data.user;
    this.buttons[0].enabled = Boolean(this.user.photo);
  }
}
