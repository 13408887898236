import { Component } from '@angular/core';

import { AlertService } from '@app/services/alert/alert.service';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { LoadingService } from '@app/services/loading/loading.service';
import { Logger } from '@app/services/logger/logger.service';
import { ModalManager } from '@app/services/modal-manager/modal-manager';
import { UserService } from '@app/services/user/user.service';
import { Language, DEFAULT_LANGUAGE } from '@app/shared/constants/language/language.const';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-select-modal',
  templateUrl: './language-select-modal.component.html',
  styleUrls: ['./language-select-modal.component.scss']
})
export class LanguageSelectModalComponent {
  private readonly logger: Logger = new Logger('Language select modal');
  language!: Language;
  languageCode: string;

  readonly buttons: Buttons[] = [
    {
      text: 'CANCEL',
      type: 'secondary',
      enabled: true,
      onClick: () => this.close(false)
    },
    {
      text: 'EMPLOYEE_PORTAL.SAVE',
      type: 'primary',
      enabled: false,
      onClick: () => this.sendLanguage(this.language)
    }
  ];

  constructor(
    private modalManager: ModalManager,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private alertService: AlertService
  ) {
    this.languageCode = this.userService.getLanguageCode();
  }

  close = (success) => this.modalManager.dismissMatModal(success);

  async updateLanguage({ code, country }: Language) {
    this.language = { code, country };
    this.buttons[1].enabled = code !== this.languageCode;
  }
  async sendLanguage({ code, country }: Language) {
    this.loadingService.show();
    try {
      await this.userService.setLanguage(code || DEFAULT_LANGUAGE, country);
    } catch (error) {
      this.showToastLanguageError();
      this.logger.error(`The language could not be changed: ${error}`);
    } finally {
      this.analyticsService.sendEvent(CategoriesAnalytics.EDIT_LANGUAGE, {
        [ActionsAnalytics.CLICKACTION]: ActionsAnalytics[`LANGUAGE_${code.toUpperCase()}`]
      });
      this.loadingService.hide();
      this.modalManager.dismissMatModal();
    }
  }

  private getMessage(key: string): string {
    return this.translate.instant(key);
  }

  private showToastLanguageError() {
    this.alertService.showError(
      this.getMessage('ERRORS_TOASTS.ERROR_TITLE'),
      this.getMessage('ERRORS_TOASTS.LANGUAGE_ERROR'),
      3000
    );
  }
}
