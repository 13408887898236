export interface UserLogin {
  login_type: UserLoginType;
  phones: string[];
  app_type: UserHome;
}

export interface UserLoginPhoneResponse {
  phone: string;
}
export enum UserLoginType {
  ADFS = 'adfs',
  AZURE = 'azure'
}

export enum UserHome {
  DEFAULT = 'default',
  EXEMPLOYEE = 'exemployee',
  EMPLOYEE = 'employee'
}
