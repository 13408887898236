export enum Permissions {
  MODEL = 'ACTIVO2.MODELO',
  PERSONAL_DATA = 'PTRAB.PERSONAL_DATA',

  // Payslips
  PAYSLIPS = 'PTRAB.PAYSLIPS',
  DIRECT_DEBIT = 'PTRAB.DIRECTDEBIT',
  WAGE_GARNISHMENT = 'PTRAB.WAGEGARNISHMENT',
  CAR_TRANSFER = 'PTRAB.CARTRANSFER',

  // Irpf
  IRPF = 'PTRAB.PIT',
  WITHHOLDING_CERTIFICATE = 'PTRAB.WITHHOLDING_CERTIFICATE',
  VOLUNTARY_WITHHOLDING = 'PTRAB.VOLUNTARY_WITHHOLDING',
  PERCENTAGE_READJUSTMENT = 'PTRAB.PERCENTAGE_READJUSTMENT',
  WHAT_IS_IRPF = 'PTRAB.WHAT_IS_PIT',

  // Certificates
  REMUNERATION_CERTIFICATES = 'PTRAB.REMUNERATION_CERTIFICATES',
  PERMISSIONS_AND_LEAVES_CERTIFICATES = 'PTRAB.PERMISSIONS_AND_LEAVES_CERTIFICATES',
  HIRING_CERTIFICATES = 'PTRAB.HIRING_CERTIFICATES',
  EMPLOYMENT_STATUS_CERT = 'PTRAB.EMPLOYMENT_STATUS_CERT',
  WORKDAY_CERT = 'PTRAB.WORKDAY_CERTIFICATE',
  PENDING_DOCUMENTS = 'PTRAB.PENDING_DOCUMENTS',
  CONTRACT_CERTIFICATE = 'PTRAB.CONTRACT_CERTIFICATE',

  // PORTUGAL PERMISSIONS
  PORTUGAL_PERMISSIONS = 'PORTUGAL_COMPANY',

  // MOT PERMISSIONS
  MOT_PERMISSION = 'MOT',
  MOT_CALENDAR = 'MOT.CALENDAR',
  MOT_MONTH_VIEW = 'MOT.MONTH_VIEW',
  MOT_WEEK_VIEW = 'MOT.WEEK_VIEW',
  MOT_SIGNED_TIMETABLES = 'MOT.SIGNED_TIMETABLES',
  MOT_VACATIONS = 'MOT.VACATIONS',
  MOT_VACATIONS_CALENDAR = 'MOT.VACATIONS_CALENDAR',
  MOT_VACATIONS_PDF = 'MOT.VACATIONS_PDF',
  MOT_SIGNED_VACATIONS = 'MOT.SIGNED_VACATIONS',
  MOT_VACATIONS_PROPOSAL_LIST = 'MOT.VACATIONS_PROPOSALS_LIST',
  MOT_VACATIONS_PROPOSAL_EDIT = 'MOT.VACATIONS_PROPOSALS_EDIT',

  // MOMO PERMISSIONS
  MOMO_VACATIONS_PROPOSAL_LIST = 'MOMO.VACATIONS_PROPOSALS_LIST',
  MOMO_VACATIONS_PROPOSAL_EDIT = 'MOMO.VACATIONS_PROPOSALS_EDIT',
  MOMO_VACATIONS = 'MOMO.VACATIONS',

  // NOTIFICATIONS PERMISSIONS
  NOTIFICATIONS = 'NOTIFICATIONS_PAGE',
  NOTIFICATIONS_PUBLICATIONS = 'NOTIFICATIONS_PAGE.PUBLICATIONS',
  NOTIFICATIONS_INDIVIDUAL = 'NOTIFICATIONS_PAGE.INDIVIDUALS',

  // PERSONAL DATA
  LANGUAGES = 'CURRICULUM.LANGUAGES',
  STUDIES = 'CURRICULUM.QUALIFICATIONS',

  // Logistic Anual Calendar
  ORGANIZA2_CALENDAR = 'ORGANIZA2.CALENDAR',
  ORGANIZA2_CALENDAR_PDF = 'ORGANIZA2.CALENDAR_PDF',
  ORGANIZA2_HOURS_BAG = 'ORGANIZA2.HOURS_BAG',

  // Local Publications
  LOCAL_PUBLICATION = 'CONTENT.LOCAL_PUBLICATIONS',
  SEGMENTS = 'CONTENT.LIST_SEGMENTS',

  // Discontinous fixed access
  DISCOUNTINOUS_FIXED = 'INACTIVE_EMPLOYEE',

  // On boarding
  ONBOARDING = 'ONBOARDING',

  // Personal data update
  TEMP_PERSONAL_DATA = 'TEMP.PERSONAL_DATA',

  // Exmployees
  EXEMPLOYEE_WITHHOLDING = 'PTRAB.EXEMPLOYEE_WITHHOLDING_CERTIFICATE',
  EXEMPLOYEE_CONTRIBUTIONS = 'PTRAB.EXEMPLOYEE_CONTRIBUTIONS_CERTIFICATE',
  EXEMPLOYEE_PAYSLIPS = 'PTRAB.EXEMPLOYEE_PAYSLIPS',

  // Excedence
  LEAVE_EMPLOYEE = 'LEAVE_EMPLOYEE'
}
