import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { buildDropdownYearsItems, mapDropdownItem } from '@app/ptrab/components/dropdown/dropdown.utils';
import { ApiPtrabUrls, PdfService, WithholdingService } from '@app/ptrab/services';
import { DropdownItem } from '@app/ptrab/shared/models/dropdown';
import {
  PortugueseWithholding,
  PortugueseWithholdingCertificate,
  PortugueseWithholdingDropdownItem
} from '@app/ptrab/shared/models/withholding';
import { Logger } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics } from '@app/services/analytics/models/analytics.enum';

@Component({
  selector: 'app-ptrab-withholding-certificate',
  templateUrl: './withholding-certificate.component.html',
  styleUrls: ['./withholding-certificate.component.scss']
})
export class WithholdingCertificateComponent implements OnInit, OnChanges {
  private static numberOfYears = 6;
  private isDownloadingCertificate!: boolean;
  @Input() isPortugueseUser!: boolean;
  @Input() isEmployee!: boolean;
  @Input() reloadData!: boolean;

  isListLoading = true;
  dropdownItems: DropdownItem[] = [];
  dropdownOpened!: boolean;
  renderDropdown = false;
  year!: number;
  ptText!: string;
  errorOcurred!: ErrorMsgEnum | null;
  portugueseItemsByYear: PortugueseWithholdingDropdownItem[] = [];

  private logger = new Logger('WithholdingCertificateComponent');

  constructor(
    private apiUrls: ApiPtrabUrls,
    private pdfService: PdfService,
    private analyticsService: AnalyticsService,
    private withholdingService: WithholdingService
  ) {}

  ngOnInit(): void {
    this.initializeErrorHandling();
    this.initDropdown();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reloadData.currentValue) {
      this.errorOcurred = null;
      this.isListLoading = true;
      this.initDropdown();
    }
  }

  get hasProviderError(): boolean {
    return this.errorOcurred === ErrorMsgEnum.SERVER_ERROR || this.errorOcurred === ErrorMsgEnum.SERVICE_LOCKED;
  }

  // eslint-disable-next-line
  setYear(year: number) {
    this.year = year;
  }

  dropdownOpenAction(event: boolean) {
    this.dropdownOpened = event;
  }

  async downloadCertificate(item?: PortugueseWithholdingCertificate) {
    let certificateUrl = '';

    if (this.isEmployee) {
      certificateUrl = this.isPortugueseUser
        ? this.apiUrls.withholding.portugueseCertificate(this.year, item?.company_code, item?.certificate_type)
        : this.apiUrls.withholding.certificate(this.year);
    } else {
      certificateUrl = this.isPortugueseUser
        ? this.apiUrls.exemployee.withholdingPt(this.year, item?.company_code, item?.certificate_type)
        : this.apiUrls.exemployee.withholding(this.year);
    }
    const errorMsg = 'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE_ERROR_DESC';

    if (this.isDownloadingCertificate) {
      return;
    }

    try {
      this.isDownloadingCertificate = true;

      this.pdfService.fetch(certificateUrl, errorMsg);
      if (this.isPortugueseUser) {
        this.analyticsService.sendEvent(CategoriesAnalytics.DOWNLOAD, {
          [ActionsAnalytics.DOWNLOAD]: item?.certificate_type,
          [ActionsAnalytics.CLICKLABEL]: this.year.toString()
        });
      } else {
        this.analyticsService.sendEvent(CategoriesAnalytics.SEE, {
          [ActionsAnalytics.CLICKACTION]: ActionsAnalytics.WITHHOLDING_CERTIFICATE,
          [ActionsAnalytics.CLICKLABEL]: this.year.toString()
        });
      }
    } catch (err) {
      this.logger.error(err);
    } finally {
      this.isDownloadingCertificate = false;
    }
  }

  private initDropdown() {
    if (this.isPortugueseUser) {
      this.withholdingService
        .getPortugueseWithholdingList(this.isEmployee)
        .pipe(finalize(() => (this.isListLoading = false)))
        .subscribe((data) => {
          this.ptText = data.text;
          if (data && data.list) {
            this.dropdownItems = this.mapPortugalYearsDropdown(data);
            this.dropdownItems[0].selected = true;
            this.year = this.dropdownItems[0].value;
          }
        });
    } else {
      this.dropdownItems = buildDropdownYearsItems(WithholdingCertificateComponent.numberOfYears);
      this.dropdownItems[0].selected = true;
      this.year = this.dropdownItems[0].value;
      this.isListLoading = false;
      this.renderDropdown = true;
    }
  }

  private mapPortugalYearsDropdown(items: PortugueseWithholding) {
    const dropdownItems: DropdownItem[] = [];

    items.list.forEach((item) => {
      const yearIndex = this.portugueseItemsByYear.findIndex((mappedItem) => mappedItem.year === item.year);
      const certificateItem: PortugueseWithholdingCertificate = {
        certificate_type: item.certificate_type,
        description: item.description,
        company_code: item.company_code
      };

      if (yearIndex !== -1) {
        this.portugueseItemsByYear[yearIndex].certificates.push(certificateItem);
      } else {
        this.portugueseItemsByYear.push({ year: item.year, certificates: [certificateItem] });
      }
    });

    for (const item of this.portugueseItemsByYear) {
      const dropdownItem = mapDropdownItem(parseInt(item.year, 10));
      dropdownItems.push(dropdownItem);
    }

    return dropdownItems;
  }

  private initializeErrorHandling() {
    this.withholdingService.onError$.subscribe((errorType) => {
      this.errorOcurred = errorType;
    });
  }
}
