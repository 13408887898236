<app-icon-button
  [buttonIcon]="buttonIcon"
  [buttonText]="buttonText"
  buttonType="primary"
  (buttonClicked)="toggleDropdownButtons()">
</app-icon-button>

<div class="dropdown-buttons" *ngIf="dropdownOpened">
  <app-icon-button
    [buttonIcon]="button.icon"
    [buttonText]="button.text"
    buttonType="secondary"
    *ngFor="let button of dropdownButtons; trackBy: trackByItems"
    (buttonClicked)="redirectTo(button.urlRedirect)">
  </app-icon-button>
</div>
