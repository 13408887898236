import { MLoggerLevel } from '@mercadona/core/logger';
import { MTelemetryConfig, MTraces } from '@mercadona/core/telemetry';
import { MEnviroment } from '@mercadona/core/utils/environment';

import { AppVersion } from './environments.enum';

const base_api_url = 'https://back.activo2.itg.mercadona.com';

const localEnvironment = {
  name: 'dev',
  production: false,
  isDebugMode: true,
  isMocksMode: false,
  API_URL: `${base_api_url}`,
  API_URL_USERS: `${base_api_url}`,
  API_URL_PTRAB: `${base_api_url}`,
  API_URL_MOT: `${base_api_url}`,
  API_URL_NOTIFICATIONS: `${base_api_url}`,
  WS_URL: 'ws://rt.activo2.itg.mercadona.com/ws/',
  APP_UNIVERSAL_LINKS_URL: 'https://activo2.itg.mercadona.com',
  authentication: {
    instance: 'https://fed.itgmercadona.com/adfs',
    clientId: '8a5f4804-2a34-47bd-b51f-b64b5524b281',
    redirectUri: 'http://localhost:4200',
    postLogoutRedirectUri: 'http://localhost:4200',
    scope: 'openid',
    resource: 'api://mercadona/audience/services/activo2/v1',
    recoverLogin:
      'https://usuarios.itg.mercadona.com/cacci/web/ope.htm?operation=cacci.flow.portal.mostrarRecuperarLogin&idSistema=ACTIVO2&descSistema=ACTIVO2&redirect_uri=https://activo2.itg.mercadona.com'
  },
  azure: {
    clientId: '11f12174-5463-4038-b851-d63b94cede75',
    tenantId: '9444691f-85a0-43f9-9e66-6fe9bcfbfc8f',
    redirectUri: 'http://localhost:4200',
    postLogoutRedirectUri: 'http://localhost:4200',
    resource: 'api://mercadona/audience/services/activo2/v1/.default',
    authority: 'https://login.microsoftonline.com',
    scope: 'openid'
  },
  guards: {
    mobileweb: true
  },
  minimizeRealTimeRequests: true,
  googleAnalyticsConfigKey: 'G-PZTED9WYM1',
  recoveryPassword: 'https://usuarios.itg.mercadona.com/cacci/recuperar.jsp',
  fullAccessPtrab: false,
  restService: {
    url: `${base_api_url}`,
    scope: [],
    resource: ''
  },
  telemetryConfig: {
    url: 'https://otelcol-front.dev.gcp.mercadona.com/v1/traces',
    traces: MTraces.DEFAULT,
    ignoreUrls: [new RegExp(/vimeo.com/)]
  } as MTelemetryConfig,
  logLevel: MLoggerLevel.TRACE
};

const envInstance = new MEnviroment('activo2-front-webapp', localEnvironment);

export const ENV = { ...envInstance.environment, appVersion: AppVersion };
export const TELEMETRY_CONFIG = ENV.telemetryConfig;
