import { CivilStatus } from './civil-status';
import { DisabilitiesPt, YieldsOwnership } from '../enums/irs.enum';
import { IrsDataResponse, IrsWithholdingResponse } from '../interfaces/irs-data-response.interface';

export interface WorkerPt {
  civilStatus?: CivilStatus;
  civilStatusId: number;
  civilStatusName: string;
  disability: DisabilitiesPt;
  percentage: number;
  fiscalStatus: number;
  incomeTitularity: YieldsOwnership;
}

export interface Spouse {
  disability: DisabilitiesPt;
  percentage: number;
}

export interface Dependant {
  id: number | null;
  birthdate: string;
  disability: DisabilitiesPt;
  percentage: number;
}

export interface YoungBenefit {
  years: number;
}

export class IrsModel {
  worker: WorkerPt;
  spouse: Spouse;
  dependants: Dependant[];
  youngBenefit: YoungBenefit;

  constructor(irsResponse: IrsDataResponse) {
    this.worker = {
      civilStatusId: irsResponse.worker.civil_status,
      civilStatusName: irsResponse.worker.civil_status_name!,
      disability: Object.values(DisabilitiesPt)[irsResponse.worker.disability_type],
      percentage: irsResponse.worker.disability_percentage,
      fiscalStatus: irsResponse.worker.fiscal_status!,
      incomeTitularity: irsResponse.worker.income_titularity
    };
    this.spouse = {
      disability: Object.values(DisabilitiesPt)[irsResponse.spouse.disability_type],
      percentage: irsResponse.spouse.disability_percentage
    };
    this.dependants = irsResponse.dependents.map((dependant) => {
      return {
        id: dependant.id,
        birthdate: dependant.birth_date,
        disability: Object.values(DisabilitiesPt)[dependant.disability_type],
        percentage: dependant.disability_percentage
      };
    });
    this.youngBenefit = {
      years: irsResponse.irs_jovem.benefit_years
    };
  }

  hasDisability(key: string): boolean {
    return this[key].disability.getIndex() !== DisabilitiesPt.NO.getIndex();
  }

  hasYoungBenefit(): boolean {
    return this.youngBenefit && this.youngBenefit.years !== 0;
  }
}

export class IrsWithholdingModel {
  maxRetention: number;
  voluntaryRetention: number;
  multiplyFactor: number;

  constructor(irsWithholdingResponse: IrsWithholdingResponse) {
    this.maxRetention = irsWithholdingResponse.max_retention;
    this.voluntaryRetention = irsWithholdingResponse.voluntary_retention;
    this.multiplyFactor = irsWithholdingResponse.multiply_factor;
  }
}
