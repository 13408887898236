export const isTablet = (userAgent = navigator.userAgent || navigator.vendor) => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const smallest = Math.min(width, height);
  const largest = Math.max(width, height);
  const hasTabletBounds = smallest > 460 && smallest < 820 && largest > 780 && largest < 1400;

  const jsTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );

  return (isMobileWeb(userAgent) && hasTabletBounds) || jsTablet;
};

export const isIOS = (userAgent = navigator.userAgent || navigator.vendor) => {
  return /iPad|iPhone|iPod/.test(userAgent);
};

export const isIpad = (userAgent = navigator.userAgent || navigator.vendor) => {
  return isTablet(userAgent) && isIOS(userAgent);
};

export const isAndroid = (userAgent = navigator.userAgent || navigator.vendor) => {
  return /android/i.test(userAgent);
};

export const isMobileWeb = (userAgent = navigator.userAgent || navigator.vendor) => {
  const isAndroidDevice = /android/i.test(userAgent);
  const isIosDevice = /iPad|iPhone|iPod/.test(userAgent);

  return isAndroidDevice || isIosDevice;
};
