import { Directive, ElementRef, Input } from '@angular/core';

import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Directive({
  selector: '[app-swiper]'
})
export class SwiperDirective {
  @Input() config!: SwiperOptions;

  constructor(private el: ElementRef<SwiperContainer>) {}

  initializeSwiper() {
    Object.assign(this.el.nativeElement, this.config);
    this.el.nativeElement.initialize();
  }
}
