<div class="arrow"></div>
<emoji-mart
  [darkMode]="false"
  [showPreview]="false"
  [totalFrequentLines]="1"
  [enableSearch]="false"
  [isNative]="true"
  [perLine]="7"
  [i18n]="emojiLabels"
  (emojiSelect)="emojiSelected.emit($event)"></emoji-mart>
