import { Component, ElementRef, EventEmitter, HostListener, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';

import { NetworkService } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics, PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { NavigationHelperService } from '@services/navigation/navigation.helper.service';
import { NavigationEvent, NavigationEvents, NavigationService } from '@services/navigation/navigation.service';
import { PAGES } from '@shared/enums/pages/pages.enum';

import { MPopoverDirective } from '@mercadona/components/popover';

interface Segment {
  value: string;
  text: string;
  icon: string;
  analyticsLabel: string;
  isActive?: boolean;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges {
  @ViewChild('mPopover') popover!: MPopoverDirective;
  @Output() sectionEmitter = new EventEmitter<string>();
  readonly pageMore = PAGES.MORE;
  pageMoreActive!: boolean;

  currentSegment!: string | null | undefined;
  segments: Segment[];
  initialSegment!: string;

  pageAnalytics!: PagesAnalytics;
  private refPagesMore = [PAGES.DEVICE_INFO, PAGES.TERMS_AND_CONDITIONS, PAGES.COOKIES, PAGES.DATA_TREATMENT];
  active!: PAGES;

  constructor(
    private navService: NavigationService,
    private navhelper: NavigationHelperService,
    private networkService: NetworkService,
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private element: ElementRef
  ) {
    this.activatedRoute.data.subscribe((data: Data) => {
      this.pageAnalytics = data.pageAnalytics;
    });
    this.segments = this.getSegments();
  }

  @HostListener('document:click', ['$event'])
  /* istanbul ignore next */
  clickout(ev) {
    if (this.pageMoreActive && !this.element.nativeElement.contains(ev.target)) {
      this.checkIsActive(this.initialSegment);
      this.resetToInitialInstanceSegment();
    }
  }

  ngOnInit() {
    const pageActivate = this.refPagesMore.find((page) => page === (this.navhelper.getActiveRoute() as PAGES));
    const pageIsPublications = this.navhelper.getActiveRoute() === PAGES.PUBLICATIONS;

    this.active = pageIsPublications ? PAGES.HOME : pageActivate ? PAGES.MORE : this.navhelper.getActiveRoute();
    this.currentSegment = this.active;
    this.initialSegment = this.active;
    this.checkIsActive(this.currentSegment);
  }

  ngOnChanges() {
    this.currentSegment = this.active;
  }

  openPage(segment: Segment) {
    this.networkService.doIfConnection(() => {
      this.checkIsActive(segment.value);

      const navEvent = new NavigationEvent(NavigationEvents.SetRoot, { path: segment.value });
      this.navService.navigate(navEvent);
      this.sendAnalyticsEvent(segment);

      this.resetToInitialInstanceSegment();
    }, this.currentSegment as MSafeAny);

    this.sectionEmitter.emit(this.currentSegment as string);
  }

  checkIsActive(segmentSelect: string) {
    this.segments.forEach((segment) => (segment.isActive = segment.value === segmentSelect));
    this.pageMoreActive = Boolean(segmentSelect === this.pageMore);
  }

  resetToInitialInstanceSegment() {
    this.currentSegment = this.initialSegment;
  }

  trackByItems(item: Segment) {
    return item;
  }

  private getSegments(): Segment[] {
    return [
      {
        value: PAGES.HOME,
        icon: 'app-icon-home',
        text: 'HOME',
        analyticsLabel: CategoriesAnalytics.HOME,
        isActive: false
      },
      {
        value: PAGES.MODEL,
        icon: 'app-icon-content-model',
        text: 'MODEL',
        analyticsLabel: PagesAnalytics.MODEL,
        isActive: false
      },
      {
        value: PAGES.EXAMPLES,
        icon: 'app-icon-shared-ideas',
        text: 'EXAMPLES',
        analyticsLabel: PagesAnalytics.EXAMPLES,
        isActive: false
      }
    ];
  }

  private sendAnalyticsEvent(segment: Segment) {
    this.analyticsService.sendEvent(CategoriesAnalytics.MENU_INFERIOR, {
      [ActionsAnalytics.CLICKACTION]: segment.analyticsLabel
    });
  }
}
