import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

export enum ErrorTypes {
  ERROR_LOGIN_SSO = 'ERROR_LOGIN_SSO',
  GETTING_USER_INFO = 'GETTING_USER_INFO',
  GETTING_USER_UPDATEABLE_INFO = 'GETTING_USER_UPDATEABLE_INFO',
  GET_REFRESH_TOKEN = 'GET_REFRESH_TOKEN',
  INAPP_LOGIN_CLOSE = 'INAPP_LOGIN_CLOSE',
  INAPP_LOGIN_ERROR = 'INAPP_LOGIN_ERROR',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  STORAGE_USER_LOGIN = 'STORAGE_USER_LOGIN',
  WEBSOCKET_CONNECTION = 'WEBSOCKET_CONNECTION',
  MAINTENANCE_MODE = 'MAINTENANCE',
  FORBIDDEN = 'FORBIDDEN',
  OFFLINE = 'OFFLINE',
  USER_BANNED = 'USER_BANNED',
  NOT_FOUND = 'NOT_FOUND',
  TERMS_NOT_ACCEPTED = 'TERMS_NOT_ACCEPTED',
  ON_BOARDING_REQUIRED = 'ON_BOARDING_REQUIRED',
  OTHER = 'OTHER',
  CLOSE_APP = 'CLOSE_APP',
  SECTION_MAINTENANCE = 'SECTION_MAINTENANCE',
  EMPTY_SLOTS = 'EMPTY_SLOTS'
}

export enum ErrorCodes {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  NOT_ACCEPTABLE = 406,
  PRECONDITION_FAILED = 412,
  MAINTENANCE = 418,
  LOCKED = 423,
  UNAVAILABLE = 417,
  UPGRADE_REQUIRED = 426,
  INTERNAL = 500,
  BAD_GATEWAY = 502,
  SECTION_MAINTENANCE = 503
}

export enum ErrorMessages {
  ADDRESS_UNREACHABLE = 'net::ERR_ADDRESS_UNREACHABLE',
  NETWORK_ERROR = 'net::ERR_INTERNET_DISCONNECTED',
  RECOVER_PASSWORD = 'CDVWebViewDelegate: Navigation started when state=1',
  NAME_NOT_RESOLVED = 'net::ERR_NAME_NOT_RESOLVED',
  USER_BANNED = 'activo2-001',
  PTRAB_UNAUTHORIZED = 'activo2-ptrab-unauthorized',
  PTRAB_BLOCKED = 'activo2-ptrab-blocked',
  INCORRECT_PASSWORD = 'Incorrect Password',
  WITHHOLDING_CERTIFICATE = 'PTRAB-MC-RQ-OM-FIS-1021',
  RISKY_PREGNANCY = 'PTRAB-MC-RQ-OM-INF-1001',
  EMPTY_GARNISHMENT = 'PTRAB-MC-RQ-OM-FIS-1022',
  EMPTY_PAYSLIPS = 'PTRAB-MC-RQ-OM-INF-1002',
  SESSION_REFRESH_REQUIRED = 'SESSION REFRESH REQUIRED',
  INVALID_EMAIL_FORMAT = 'Introduzca una dirección de correo electrónico válida.',
  TOKEN_EXPIRED = 16,
  RESOURCE_LOCKED = 'Resource locked',
  PERM_DEACTIVATED = 'ERR.PERM.DEACTIVATED',
  INVALID_IBAN = 'PTRAB-MC-RQ-OM-ERR-1019',
  EXTERNAL_CLIENT_UNAVAILABLE = 'EXTERNAL-CLIENT-UNAVAILABLE',
  EMPTY_SLOTS = 'EMPTY-SLOTS-ERROR',
  FIXED_DISCONTINUOUS_CERT_DOWNLOAD = 'PTRAB-MC-RQ-OM-LAB-1011'
}

export interface ErrorMsg {
  code: ErrorMessages;
  message: string;
}

export class AppError {
  private static readonly criticalErrors: ErrorTypes[] = [
    ErrorTypes.GETTING_USER_INFO,
    ErrorTypes.GET_REFRESH_TOKEN,
    ErrorTypes.INAPP_LOGIN_ERROR,
    ErrorTypes.STORAGE_USER_LOGIN,
    ErrorTypes.TERMS_NOT_ACCEPTED
  ];

  private static readonly authErrors: ErrorTypes[] = [
    ErrorTypes.ERROR_LOGIN_SSO,
    ErrorTypes.REFRESH_TOKEN,
    ErrorTypes.USER_BANNED
  ];

  constructor(public type: ErrorTypes, public message: string = '', public params?: MSafeAny) {}

  isOfType(type: ErrorTypes): boolean {
    return this.type === type;
  }

  isOfSomeType(types: ErrorTypes[]): boolean {
    return types.some((type) => this.type === type);
  }

  isCritical(): boolean {
    return AppError.criticalErrors.some((type) => type === this.type);
  }

  isAuthError(): boolean {
    return AppError.authErrors.some((type) => type === this.type);
  }

  hasMessage(message: ErrorMessages): boolean {
    return this.message === message;
  }

  hasOneOfMessages(messages: ErrorMessages[]): boolean {
    return messages.some((message) => this.message === message);
  }
}
