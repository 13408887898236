import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { TwoFactorService } from '@app/ptrab/services/two-factor/two-factor.service';
import {
  TwoFactorError,
  TwoFactorOperationCode,
  TwoFactorUserStatusResponse,
  TwoFactorValidationResult,
  TwoFactorValidationStatus
} from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { Logger, ModalManager, AlertService } from '@app/services';
import { ErrorCodes } from '@app/services/error/error.model';
import { LoadingService } from '@app/services/loading/loading.service';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { getMinutesFromSeconds } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bank-code-validation',
  templateUrl: './bank-code-validation.component.html',
  styleUrls: ['./bank-code-validation.component.scss']
})
export class BankCodeValidationComponent implements OnInit, OnDestroy {
  operation!: TwoFactorOperationCode;

  buttons: Buttons[] = [
    {
      text: 'EMPLOYEE_PORTAL.CONTINUE',
      type: 'primary',
      enabled: false,
      onClick: () => this.validateCode()
    }
  ];

  form = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)])
  });
  isCodeWrong = false;
  isCodeWrongTwice = false;
  errorHint = { account: '' };
  maxTriesExceeded = false;
  remainingMinutes!: number;
  isHelpMsgOpen = false;
  errorMsg!: string;

  private logger = new Logger('BankCodeValidationComponent');
  private twoFactorservice: TwoFactorService;
  private subscription!: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private translate: TranslateService,
    private modalManager: ModalManager,
    injector: Injector
  ) {
    this.twoFactorservice = injector.get(TwoFactorService);
  }

  ngOnInit() {
    this.operation = this.data.operation;

    this.subscription = this.form.valueChanges.subscribe(() => {
      this.buttons[0].enabled = this.form.valid;
    });
  }

  toggleHelpMsg() {
    this.isHelpMsgOpen = !this.isHelpMsgOpen;
  }

  validateCode() {
    this.loadingService.show();
    this.twoFactorservice
      .validateCode(this.operation, this.form.get('code')?.value as string)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (hash) => {
          const result: TwoFactorValidationResult = { status: TwoFactorValidationStatus.SUCCESS, hash };
          this.modalManager.dismissMatModal(result);
        },
        (errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === ErrorCodes.LOCKED) {
            this.handleServiceLocked();
            return;
          }

          if (errorResponse.status === ErrorCodes.BAD_REQUEST && errorResponse.error.code) {
            this.handleTwoFactorError(errorResponse.error);
            return;
          }

          this.handleGenericError(errorResponse);
        }
      );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private async handleTwoFactorError(error: { code: TwoFactorError; hint: string }) {
    const { code, hint } = error;
    if (code === TwoFactorError.VALIDATION_CODE_INVALID) {
      this.errorHint.account = hint;
      this.isCodeWrongTwice = Boolean(this.isCodeWrong);
      this.isCodeWrong = true;
      this.errorMsg = this.isCodeWrongTwice
        ? 'EMPLOYEE_PORTAL.BANK_2FA.ENTERED_ACCOUNT_NOT_CORRECT'
        : 'EMPLOYEE_PORTAL.BANK_2FA.LAST_DIGITS_ACCOUNT_NOT_MATCHING';
      return;
    }
    if (code === TwoFactorError.VALIDATION_CODE_MAX_TRIES_EXCEEDED) {
      const userStatus = (await this.twoFactorservice.getUserStatus().toPromise()) as TwoFactorUserStatusResponse;
      this.remainingMinutes = getMinutesFromSeconds(userStatus.remaining_time);
      this.setMaxTriesError();
      return;
    }
    this.handleGenericError(error);
  }

  private handleGenericError(error: MSafeAny) {
    this.alertService.showError(
      this.translate.instant('EMPLOYEE_PORTAL.TWO_FACTOR_TITLE_ERROR'),
      this.translate.instant('ERROR_MESSAGES.GENERIC_FAIL_MESSAGE')
    );
    this.logger.error(error);
  }

  private handleServiceLocked() {
    this.alertService.showWarning(
      this.translate.instant('EMPLOYEE_PORTAL.MODIFICATION_NOT_AVAILABLE'),
      this.translate.instant('EMPLOYEE_PORTAL.PAYSLIP_REQUEST_NOT_AVAILABLE')
    );
  }

  private setMaxTriesError() {
    this.maxTriesExceeded = true;
    this.buttons = [
      {
        text: 'EMPLOYEE_PORTAL.BACK',
        type: 'primary',
        enabled: true,
        onClick: () => this.dismissBlockedUser()
      }
    ];
  }

  private dismissBlockedUser() {
    const result: TwoFactorValidationResult = { status: TwoFactorValidationStatus.USER_BLOCKED };
    this.modalManager.dismissMatModal(result);
  }
}
