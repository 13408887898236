import { Component, HostListener, Input } from '@angular/core';

import { NavigationEvent, NavigationEvents, NavigationService } from '@app/services';
import { ILinkCard } from '@app/shared/interfaces/link-card/link-card.interface';

@Component({
  selector: 'app-icon-title-card',
  templateUrl: 'icon-title-card.component.html',
  styleUrls: ['./icon-title-card.component.scss']
})
export class IconTitleCardComponent {
  @Input() data!: ILinkCard;

  constructor(private navigationService: NavigationService) {}

  @HostListener('click') onClick() {
    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: this.data.link
    });

    this.navigationService.navigate(navEvent);
  }
}
