import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { SuccessModalComponent } from '@app/ptrab/components/modals/success-modal/success-modal.component';
import { ApiPtrabUrls, PdfService } from '@app/ptrab/services';
import { IrpfService } from '@app/ptrab/services/irpf/irpf.service';
import { TwoFactorController } from '@app/ptrab/services/two-factor/two-factor.controller';
import { UNLIMITED_TIME } from '@app/ptrab/shared/constants/constants';
import {
  ANCESTORS_ERRORS,
  DESCENDANT_ERRORS,
  IRPF_ERRORS,
  PENSION_PAYMENT_ERRORS,
  WORKER_ERRORS
} from '@app/ptrab/shared/constants/irpf.const';
import { FeedbackTypes } from '@app/ptrab/shared/enums/feedback.enum';
import { Territories } from '@app/ptrab/shared/enums/irpf.enum';
import {
  IrpfDataPostResponse,
  IrpfDataSection,
  IrpfDataSectionError,
  SECTIONS_IRPF
} from '@app/ptrab/shared/interfaces/irpf-section.interface';
import {
  TwoFactorAuthorization,
  TwoFactorOperationCode
} from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { IrpfView } from '@app/ptrab/shared/models/irpf-view.model';
import { IrpfModel } from '@app/ptrab/shared/models/irpf.model';
import {
  Logger,
  ModalManager,
  NavigationEvent,
  NavigationEvents,
  NavigationService,
  AlertService
} from '@app/services';
import { ErrorCodes } from '@app/services/error/error.model';
import { LoadingService } from '@app/services/loading/loading.service';
import { PTRAB_PAGES } from '@app/shared/enums/pages/pages.enum';
import { getPtrabUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';

import { IrpfViewFormatter } from '../../formatter/irpf-view.formatter';
import { IrpfSections } from '../../irpf.constants';
/* eslint-disable @typescript-eslint/naming-convention */

@Component({
  selector: 'app-ptrab-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.scss']
})
export class MyDataComponent implements OnChanges, OnDestroy {
  @Input() irpf!: IrpfModel;
  @Input() isListLoading!: boolean;

  @Output() editSection = new EventEmitter<MSafeAny>();
  @Output() reloadData = new EventEmitter<MSafeAny>();
  @Output() showInfoMessages = new EventEmitter<IrpfDataSectionError[]>();

  irpfView!: IrpfView;
  errorOcurred!: FeedbackTypes;
  preventLoadData!: boolean;
  hasChanges = false;
  sections = IrpfSections;
  irpfDataSections: IrpfDataSection[] = [];

  workerError!: IrpfDataSectionError | null;
  ancestorsError!: IrpfDataSectionError | null;
  descendantsError!: IrpfDataSectionError | null;
  pensionPaymentError!: IrpfDataSectionError | null;
  generalError!: IrpfDataSectionError | null;

  private irpfViewFormatter = new IrpfViewFormatter(this.translate, this.datePipe);
  private validation_hash!: string | undefined;
  private logger = new Logger('MyDataComponent');
  private subscriptions = new Subscription();

  constructor(
    private irpfService: IrpfService,
    private translate: TranslateService,
    private navigationService: NavigationService,
    private datePipe: DatePipe,
    private twoFactorController: TwoFactorController,
    private loadingService: LoadingService,
    private modalManager: ModalManager,
    private alertService: AlertService,
    private pdfService: PdfService,
    private apiUrls: ApiPtrabUrls
  ) {
    this.initializeErrorHandling();
    this.subscribeToLangChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.irpf) {
      this.updateDataView();
    }
  }

  async openDetail(title: string) {
    this.loadingService.show();
    const blocked = await this.twoFactorController.assertUserBlocked();
    await this.loadingService.hide();

    if (blocked) {
      return;
    }

    this.editSection.emit();
    this.goToSection(title);
  }

  get isCommonTerritory() {
    return this.irpf && this.irpf.territory && this.irpf.territory === Territories.COMMON_TERRITORY;
  }

  get hasProviderError(): boolean {
    return this.errorOcurred === FeedbackTypes.SERVER_ERROR || this.errorOcurred === FeedbackTypes.SERVICE_LOCKED;
  }

  get hasGeneralError() {
    return this.generalError;
  }

  async sendData() {
    if (!this.validation_hash) {
      try {
        this.validation_hash = await this.twoFactorController.validateOperation(TwoFactorOperationCode.PIT_DATA, false);
      } catch {
        return;
      }
    }

    this.modifyIrpfData(this.validation_hash);
  }

  downloadIrpfData() {
    this.pdfService.fetch(
      this.apiUrls.personalIncomeTax.getFile,
      'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE_ERROR_TITLE'
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  trackByItems(item: IrpfDataSection) {
    return item;
  }

  private openDescendantPage() {
    if (this.irpf && this.irpf.minimumChildrenAge) {
      this.goToPage(PTRAB_PAGES.IRPF_DESCENDANTS, this.getDescendantsParams());
    } else {
      this.alertService.showWarning(
        this.translate.instant('EMPLOYEE_PORTAL.COMMON_ERRORS.NOT_AVAILABLE'),
        this.translate.instant('EMPLOYEE_PORTAL.COMMON_ERRORS.TRY_LATER')
      );
    }
  }

  private modifyIrpfData(validation_hash: string) {
    this.loadingService.show();

    const twoFactorAuthorization: TwoFactorAuthorization = {
      validation_hash
    };

    this.irpfService.modifyIrpfData(twoFactorAuthorization).subscribe(
      (response: IrpfDataPostResponse) => this.onSuccess(response),
      (err: HttpErrorResponse) => this.onError(err)
    );
  }

  private async onSuccess(response: IrpfDataPostResponse) {
    const { info_messages } = response;

    this.modalManager.dismissAllMatModal();

    await this.loadingService.hide();

    await this.openSuccessModal();
    this.validation_hash = undefined;

    this.clearSectionErrors();

    if (info_messages && info_messages.length > 0) {
      this.showInfoMessages.emit(info_messages);
    }

    this.reloadData.emit();
  }

  private async onError(err: HttpErrorResponse) {
    await this.loadingService.hide();

    if (err.status === ErrorCodes.FORBIDDEN) {
      this.sendData();
      this.validation_hash = undefined;
      return;
    }

    this.setSectionsErrors(err);
    this.handleError(err);
  }

  private openSuccessModal() {
    return this.modalManager.openMatModal(SuccessModalComponent, {
      data: {
        titleKey: 'EMPLOYEE_PORTAL.PIT_DATA_CHANGED',
        infoMsgKey: 'EMPLOYEE_PORTAL.UPDATED_PIT_DATA',
        descriptionKey: 'EMPLOYEE_PORTAL.PERSONAL_EMAIL_SENT',
        emailQuestion: 'EMPLOYEE_PORTAL.DONT_YOU_RECEIVE_QUESTION',
        personalDataLink: 'EMPLOYEE_PORTAL.PERSONAL_DATA_LINK'
      },
      disableClose: true
    });
  }

  private hasCodeError(error) {
    return get(error, 'error.code');
  }

  private handleError(error: HttpErrorResponse) {
    this.logger.error('error response', error);

    if (!error.error || !this.hasCodeError(error)) {
      this.alertService.showError(
        this.translate.instant('EMPLOYEE_PORTAL.FAIL_MODIFY_PIT_DATA.TITLE'),
        this.translate.instant('EMPLOYEE_PORTAL.FAIL_MODIFY_PIT_DATA.MESSAGE'),
        UNLIMITED_TIME
      );
    }
  }

  private setSectionsErrors(error: HttpErrorResponse) {
    this.workerError = this.checkError(error, WORKER_ERRORS);
    this.descendantsError = this.checkError(error, DESCENDANT_ERRORS);
    this.ancestorsError = this.checkError(error, ANCESTORS_ERRORS);
    this.pensionPaymentError = this.checkError(error, PENSION_PAYMENT_ERRORS);
    this.generalError = this.checkError(error, IRPF_ERRORS);
    this.updateDataView();
  }

  private checkError(error: HttpErrorResponse, arrayCode: string[]): IrpfDataSectionError | null {
    const { code } = error.error;
    const errorOcurred = arrayCode.find((errorCode) => code === errorCode);

    if (errorOcurred) {
      return { code: errorOcurred };
    }

    return null;
  }

  private getWorkerInfoParams() {
    const { worker, territory } = this.irpf;
    return {
      worker,
      territory,
      errors: this.workerError
    };
  }

  private getDescendantsParams() {
    return {
      errors: this.descendantsError
    };
  }

  private getAscendantsParams() {
    return {
      errors: this.ancestorsError
    };
  }

  private getPensionPaymentParams() {
    return {
      errors: this.pensionPaymentError
    };
  }

  private goToPage(page: PTRAB_PAGES, params: MSafeAny = {}) {
    const navEvent = new NavigationEvent(NavigationEvents.Push, {
      path: getPtrabUrlFromMap(page),
      navParams: params
    });
    this.navigationService.navigate(navEvent);
  }

  private getFormattedData() {
    if (!this.irpf) {
      return;
    }
    this.irpfView = this.irpfViewFormatter.format(this.irpf);
    this.formatSections();
  }

  private updateDataView() {
    this.getFormattedData();
    this.hasChanges = this.irpfService.hasChanges();
    if (!this.hasChanges) {
      this.clearSectionErrors();
    }
  }

  private initializeErrorHandling() {
    this.subscriptions.add(
      this.irpfService.onError$.subscribe((errorType) => {
        this.errorOcurred = errorType;
      })
    );
  }

  private hasDisability(): boolean {
    const worker = this.irpfView.worker;
    return worker.disability.label !== null || worker.reducedMobility.label !== null;
  }

  private clearSectionErrors() {
    this.workerError = null;
    this.descendantsError = null;
    this.ancestorsError = null;
    this.pensionPaymentError = null;
    this.formatSections();
  }

  private goToSection(title: string) {
    const section = {
      WORKER: () => this.goToPage(PTRAB_PAGES.IRPF_WORKER_PAGE, this.getWorkerInfoParams()),
      DESCENDANT: () => this.openDescendantPage(),
      ANCESTOR: () => this.goToPage(PTRAB_PAGES.IRPF_ANCESTORS, this.getAscendantsParams()),
      MORTGAGE_REDUCTION: () => this.goToPage(PTRAB_PAGES.IRPF_MORTGAGE_REDUCTION),
      CEUTA_MELILLA_RESIDENCE: () => this.goToPage(PTRAB_PAGES.IRPF_CEUTA_MELILLA_RESIDENCE),
      PENSION_PAYMENT: () => this.goToPage(PTRAB_PAGES.IRPF_PENSION_PAYMENT, this.getPensionPaymentParams())
    };

    return section[title]();
  }

  private formatSections() {
    const workerData = this.isCommonTerritory || this.hasDisability() ? this.irpfView.worker : {};
    this.irpfDataSections = [
      {
        data: workerData,
        isVisible: true,
        sectionError: this.workerError,
        title: this.sections.WORKER,
        id: SECTIONS_IRPF.WORKER
      },
      {
        data: this.irpfView.descendants,
        isVisible: true,
        sectionError: this.descendantsError,
        title: this.sections.DESCENDANT,
        id: SECTIONS_IRPF.DESCENDANT
      },
      {
        data: this.irpfView.ancestors,
        isVisible: this.isCommonTerritory,
        sectionError: this.ancestorsError,
        title: this.sections.ANCESTOR,
        id: SECTIONS_IRPF.ANCESTOR
      },
      {
        data: this.irpfView.pensionPayment,
        isVisible: true,
        sectionError: this.pensionPaymentError,
        title: this.sections.PENSION_PAYMENT,
        id: SECTIONS_IRPF.PENSION_PAYMENT
      },
      {
        data: this.irpfView.mortgageReduction,
        isVisible: this.isCommonTerritory,
        sectionError: undefined,
        title: this.sections.MORTGAGE_REDUCTION,
        id: SECTIONS_IRPF.MORTGAGE_REDUCTION
      },
      {
        data: this.irpfView.ceutaMelillaResidence,
        isVisible: this.isCommonTerritory,
        sectionError: undefined,
        title: this.sections.CEUTA_MELILLA_RESIDENCE,
        id: SECTIONS_IRPF.CEUTA_MELILLA_RESIDENCE
      }
    ];
  }

  private subscribeToLangChange() {
    this.subscriptions.add(this.translate.onLangChange.subscribe(() => this.updateDataView()));
  }
}
