import { HttpErrorResponse } from '@angular/common/http';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ENV } from 'src/environments/environment';

export const appVersion = ENV.appVersion;

export const isNullOrUndefined = <T>(obj: T | null | undefined): obj is null | undefined => {
  return typeof obj === 'undefined' || obj === null;
};

export const isNullOrEmpty = (obj: MSafeAny) => {
  return obj.length === 0 || obj === null;
};

export const objectClone = (obj: MSafeAny) => {
  return JSON.parse(JSON.stringify(obj));
};

export const objectEquals = (firstObject: MSafeAny, secondObject: MSafeAny) => {
  return JSON.stringify(firstObject) === JSON.stringify(secondObject);
};

export const blobToFile = (blob: MSafeAny, fileName: string): File => {
  blob.lastModified = Date.now();
  blob.lastModifiedDate = new Date();
  blob.name = fileName;
  return blob as File;
};

export const getBase64FileExtension = (base64: string) => {
  return base64.substring(base64.indexOf('data:') + 5, base64.lastIndexOf(';'));
};

export const isValidString = (term: string) => {
  return typeof term === 'string' && term !== '';
};

export const normalizeString = (term: string): string => {
  return term.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const removeHtmlTagsFromString = (term: string): string => {
  return term.replace(/<\/?[^>]+(>|$)/g, '');
};

export const isFalsy = (value: MSafeAny) => {
  return value === undefined || value === null || value === '' || value === 0;
};

/*  This function can be used to wait for DOM changes made by Angular like showing and hiding an element, etc.
 *   Use it with await: await domChanges();
 */
export const domChanges = (duration = 0): Promise<typeof setTimeout> => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

/**
 * This functions emits a resize event (for update view purposes)
 */
export const windowResize = () => {
  try {
    const evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

/**
 * Flattens an array.
 */
export const flatten = (list: MSafeAny[], dst?: MSafeAny[]): MSafeAny[] => {
  if (dst === undefined) dst = list;
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (Array.isArray(item)) {
      // we need to inline it.
      if (dst === list) {
        // Our assumption that the list was already flat was wrong and
        // we need to clone flat since we need to write to it.
        dst = list.slice(0, i);
      }
      flatten(item, dst);
    } else if (dst !== list) {
      dst.push(item);
    }
  }
  return dst;
};

export const applyMixins = (derivedClass: MSafeAny, baseClasses: MSafeAny[]) => {
  baseClasses.forEach((baseClass) => {
    Object.getOwnPropertyNames(baseClass.prototype).forEach((name) => {
      derivedClass.prototype[name] = baseClass.prototype[name];
    });
  });
};

export const cleanFormat = (text: string): string => cleanHTML(cleanHTMLSpaces(text?.normalize('NFKC')));

export const cleanHTML = (text: string) => text.replace(/<\/?[^>]+(>|$)/g, '').replace(new RegExp('&amp;', 'g'), '&');

export const cleanHTMLSpaces = (text: string) => {
  text = text.replace(new RegExp('<br>', 'g'), '\n');
  return text.replace(new RegExp('&nbsp;', 'g'), ' ');
};

export const cleanHTMLPartially = (text: string) => {
  text = text.replace(new RegExp('<br>', 'g'), '\n');
  text = text.replace(new RegExp('<p>', 'g'), '');
  text = text.replace(new RegExp('</p>', 'g'), '');
  return text.replace(new RegExp('&nbsp;', 'g'), ' ');
};

export const cleanHTMLAllowingFormat = (text: string): string => {
  return text
    .normalize('NFKC')
    .replace(/<p>/gm, '')
    .replace(/<\/p>/gm, '')
    .replace(/(\r\n|\n|\r)/gm, '')
    .replace(/(nbsp;)/gm, '');
};

export const newLineToBr = (text: string) => (text ? text.replace(/\n/g, '<br>') : '');

export const getMonthName = (index: number) => {
  const monthNames = [
    'EMPLOYEE_PORTAL.MONTHS.JANUARY',
    'EMPLOYEE_PORTAL.MONTHS.FEBRUARY',
    'EMPLOYEE_PORTAL.MONTHS.MARCH',
    'EMPLOYEE_PORTAL.MONTHS.APRIL',
    'EMPLOYEE_PORTAL.MONTHS.MAY',
    'EMPLOYEE_PORTAL.MONTHS.JUNE',
    'EMPLOYEE_PORTAL.MONTHS.JULY',
    'EMPLOYEE_PORTAL.MONTHS.AUGUST',
    'EMPLOYEE_PORTAL.MONTHS.SEPTEMBER',
    'EMPLOYEE_PORTAL.MONTHS.OCTOBER',
    'EMPLOYEE_PORTAL.MONTHS.NOVEMBER',
    'EMPLOYEE_PORTAL.MONTHS.DECEMBER'
  ];
  return monthNames[index];
};

export const getBooleanTranslation = (value: boolean): string => {
  return value ? 'YES' : 'NO';
};

export const getMinutesFromSeconds = (seconds: number) => {
  return Math.ceil(seconds / 60);
};

export const isJanuaryMonth = (month: number): boolean => {
  return month === 0;
};

export const getLastDayOfMonth = (year: number, month: number): number => {
  return new Date(year, month + 1, 0).getDate();
};

export const formatIban = (iban: string): string => {
  return iban
    .replace(/[^\dA-Z]/gi, '')
    .toUpperCase()
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

export const formatOutputIban = (iban: string): string => {
  const ibanWithoutSpaces = iban.replace(new RegExp('\\s', 'g'), '');

  const ibanRegex = new RegExp('.{4}(?!$)', 'g');
  const formattedIban = ibanWithoutSpaces.replace(ibanRegex, '$& ');

  return formattedIban.toUpperCase();
};

export const countSpaces = (text: string): number => {
  const spaces = text.match(/(\s+)/g);
  return spaces ? spaces.length : 0;
};

export const isNIFValid = (value: string): boolean => {
  const regExp = /^\d{8}[a-zA-Z]$/;

  if (!regExp.test(value)) {
    return false;
  }

  const num = parseInt(value.substr(0, value.length - 1), 10);
  const letter = value.substr(value.length - 1, 1).toUpperCase();
  const result = num % 23;
  const code = 'TRWAGMYFPDXBNJZSQVHLCKET';
  const expectedLetter = code.substring(result, result + 1);

  return expectedLetter === letter;
};

export const copyToClipboard = (code: string, element: HTMLElement) => {
  try {
    if ((navigator as MSafeAny).clipboard) {
      (navigator as MSafeAny).clipboard.writeText(code).catch((e) => {
        // eslint-disable-next-line no-console
        console.warn(e);
      });
    } else if ((window as MSafeAny).clipboardData) {
      // IE
      (window as MSafeAny).clipboardData.setData('text', code);
    } else {
      // other browsers, iOS, Mac OS
      const input = document.createElement('input');
      const range = document.createRange();
      const selection = window.getSelection();
      element.appendChild(input);
      input.value = code;
      input.disabled = true;
      range.selectNodeContents(input);
      selection?.removeAllRanges();

      selection?.addRange(range);
      input.setSelectionRange(0, 999999);
      document.execCommand('copy');
      element.removeChild(input);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(e);
  }
};

export const decodeResponse = (response: MSafeAny): HttpErrorResponse => {
  // eslint-disable-next-line
  if (response.error instanceof ArrayBuffer) {
    const responseBody = decodeArrayBufferError(response);
    return { ...response, ...{ error: responseBody } };
  }
  return response;
};

export const decodeArrayBufferError = (error: MSafeAny) => {
  const decodedString = String.fromCharCode.apply(null, Array.from(new Uint8Array(error.error)));

  return JSON.parse(decodedString);
};

export const removeArrayDuplicates = (array: MSafeAny[]) => {
  return [...new Set(array)];
};

// https://stackoverflow.com/questions/8348139/detect-ios-version-less-than-5-with-javascript
export const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (v !== null) {
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)];
    }
    return null;
  }
  return null;
};

export const cryptoRandom = (): number => {
  const typedArray = new Uint32Array(1);
  const randomValue = crypto.getRandomValues(typedArray)[0];
  const randomFloat = randomValue / Math.pow(2, 32);
  return randomFloat;
};

export function removeElementAt(array: MSafeAny[], index: number) {
  return array.filter((_value, i) => index !== i);
}

// This function capitalizes ever if has non word chars before the letter, for ex: ¿casa => ¿Casa
export function capitalize(value: string) {
  const regexp = /^\W*(\w{1})/;
  const newValue = value.toLowerCase();
  return newValue.replace(regexp, (v) => v.toUpperCase());
}

export async function showIOSOpenInAppBanner() {
  await domChanges(100);
  window.scrollTo(0, -200);
}
