import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AlertService } from '@app/services/alert/alert.service';
import { Logger } from '@app/services/logger/logger.service';
import { MediaPlayerService } from '@app/services/media-player/media-player.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';
import { onResume } from '@shared/utils/platform';
import Swiper, { Pagination, Zoom } from 'swiper';

import { MediaPlayerComponent } from '../media-player/media-player.component';

/* eslint-disable @typescript-eslint/naming-convention */

enum EVENTS {
  'zoom-in' = 1,
  'zoom-out',
  'touchend'
}

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slides.css', './slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SliderComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('container') sliderContainer!: ElementRef;
  @ViewChild('pagination') pagination!: ElementRef;
  @ViewChild('prevBtn') prevBtn!: ElementRef;
  @ViewChild('nextBtn') nextBtn!: ElementRef;
  @ViewChildren('slides') slides!: QueryList<ElementRef>;
  @ViewChildren('player') mediaPlayer!: QueryList<MediaPlayerComponent>;

  @Input() image: MSafeAny[] = [];
  @Input() allowSwipe = true;
  @Input() allowLoop = true;
  @Input() allowZoom = false;
  @Input() isWebview = false;
  @Input() noBackground = false;
  @Input() positionLandscape = false;
  @Input() spaceBetween = 0;
  @Input() multimediaCarousel: MSafeAny = null;
  @Input() auxUpdateCarrousel: MSafeAny[] = [];
  @Input() updateMultimedia = undefined;

  @Output() setFullscreen: EventEmitter<void> = new EventEmitter<void>();
  @Output() setFullscreenCarousel = new EventEmitter();
  @Output() slideChange = new EventEmitter<void>();
  @Output() slideChangeCarousel = new EventEmitter();
  @Output() slideIndexChange = new EventEmitter<number>();
  @Output() enterFullscreenVideo = new EventEmitter();
  @Output() exitFullscreenVideo = new EventEmitter();

  private readonly logger: Logger = new Logger('SliderComponent');
  private isReady: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  private zoomEvent: Subject<number> = new Subject<number>();
  private zoomEvent$: Observable<number> = this.zoomEvent.asObservable();

  platformPauseSubscription!: Subscription;
  platformResumeSubscription!: Subscription;
  slider!: Swiper;
  isReady$ = this.isReady.asObservable();
  isSingleImage!: boolean;
  currentSlide = 0;
  isLoadingFile = true;
  isReadyVideo = false;
  isVideoEnterFullscreen = false;
  isAppOnPause = false;
  currentPlayer!: MediaPlayerComponent | null;
  hiddenSwipeActions!: boolean;

  show = false;
  messages!: string[];

  readonly CSS_CLASSES = {
    MODAL: 'media-content-modal--fullscreen',
    PAGE: 'video-detail--fullscreen',
    TITLE: 'plyr__title-overlay'
  };

  constructor(
    private renderer: Renderer2,
    private alertService: AlertService,
    private translate: TranslateService,
    public playerService: MediaPlayerService,
    private cdr: ChangeDetectorRef
  ) {
    this.getTranslations();
  }

  ngOnChanges(): void {
    if (this.updateMultimedia !== undefined && this.mediaPlayer && this.multimediaCarousel?.videoId) {
      if (!this.updateMultimedia) {
        if (!this.platformResumeSubscription || this.platformResumeSubscription.closed) this.subscribePlatformEvents();
        this.auxUpdateCarrousel.forEach((element, index) => {
          this.rewindForwardPlayer(element, index);
        });
      } else {
        this.isLoadingFile = false;
      }
    }
  }

  ngOnInit() {
    this.isSingleImage = this.image.length === 1;
    if (this.multimediaCarousel && (!this.platformResumeSubscription || this.platformResumeSubscription.closed)) {
      this.subscribePlatformEvents();
    }
  }

  async ngAfterViewInit() {
    const singleImage = this.image.length <= 1;
    this.slider = new Swiper(this.sliderContainer.nativeElement, {
      pagination: this.getPagination(singleImage),
      preventInteractionOnTransition: true,
      allowTouchMove: this.allowSwipe,
      spaceBetween: this.spaceBetween,
      noSwipingSelector: 'button, input',
      modules: [Pagination, Zoom],
      on: {
        afterInit: () => {
          this.isReady.next(undefined);
        },
        slideChange: () => {
          if (this.multimediaCarousel) {
            this.multimediaCarousel.isCurrentIndexSlider = this.getActiveIndex();
            this.slideChangeCarousel.next(this.isVideoEnterFullscreen);
          } else {
            this.slideChange.next(null as unknown as void);
          }

          this.slideIndexChange.next(this.getActiveIndex());
        }
      },
      zoom: this.allowZoom
    });

    this.slider.update();
    this.hiddenSwipeActions = Boolean(
      this.multimediaCarousel && this.slider && this.image[this.slider.activeIndex].type === 'video'
    );
    this.cdr.detectChanges();

    if (!this.allowZoom) {
      return;
    }

    this.setZoomEvents();
    this.setSwiping(true);
  }

  trackByItems(item: MSafeAny) {
    return item;
  }

  private subscribePlatformEvents() {
    this.platformPauseSubscription = onResume().subscribe(() => {
      this.isAppOnPause = document.visibilityState === 'hidden';
    });

    this.logger.debug('Platform events slider Subscribed => ', this.isAppOnPause);
  }

  private unsubscribePlatformEvents() {
    if (this.platformPauseSubscription) {
      this.platformPauseSubscription.unsubscribe();
    }

    if (this.platformResumeSubscription) {
      this.platformResumeSubscription.unsubscribe();
    }

    this.logger.debug('Platform events slider UNSubscribed', this.isAppOnPause);
  }

  async rewindForwardPlayer(element: MSafeAny, position: number) {
    this.currentPlayer = this.mediaPlayer['_results'][position] as MediaPlayerComponent;
    const dataPositionVideo = element;
    if (this.currentPlayer) {
      try {
        this.isAppOnPause = false;
        const currentTime = this.currentPlayer.currentTimePlayer() ? this.currentPlayer.currentTimePlayer() : 0;
        if (currentTime !== 0 && dataPositionVideo.currentTime < currentTime) {
          const rewindDifference = currentTime - dataPositionVideo.currentTime;
          this.currentPlayer.rewindPlayer(rewindDifference);
        } else {
          const forwardDifference = dataPositionVideo.currentTime - currentTime;
          this.currentPlayer.forwardPlayer(forwardDifference);
        }
        this.onMutedPlayPlayer(this.currentPlayer, dataPositionVideo);
      } catch (error) {
        this.isLoadingFile = false;
      } finally {
        this.isLoadingFile = false;
      }
    } else {
      this.isLoadingFile = false;
    }
  }

  async onMutedPlayPlayer(currentPlyer: MediaPlayerComponent, element: MSafeAny) {
    try {
      this.isLoadingFile = false;
      if (element.isPlaying && !this.isAppOnPause && element.isCurrentIndexSlider === this.getActiveIndex()) {
        currentPlyer.playPlayer();
      } else {
        currentPlyer.pausePlayer();
      }
      currentPlyer.setMutedPlayer(element.muted);
    } catch (error) {
      this.isLoadingFile = false;
    }
  }

  async onPlayerReady() {
    try {
      if (!this.isReadyVideo && this.multimediaCarousel) {
        if (!this.noBackground) this.isLoadingFile = true;
        if (!this.platformResumeSubscription || this.platformResumeSubscription.closed) this.subscribePlatformEvents();
        this.auxUpdateCarrousel.forEach((element, index) => {
          this.rewindForwardPlayer(element, index);
        });
        this.isReadyVideo = true;
      }
    } catch (err) {
      this.isLoadingFile = false;
      this.logger.error(err);
    }
  }

  onLoadedData() {
    this.isLoadingFile = false;
    this.logger.debug(`Video data has been loaded at ${new Date().toLocaleTimeString('es')}`);
  }

  onLoadedMetadata() {
    this.isLoadingFile = false;
    this.logger.debug(`Video metadata has been loaded at ${new Date().toLocaleTimeString('es')}`);
  }

  async onEnterFullscreen() {
    this.isVideoEnterFullscreen = true;
    document.body.classList.add(this.CSS_CLASSES.PAGE);
    this.currentPlayer = this.mediaPlayer['_results'][this.multimediaCarousel.positionPlayer] as MediaPlayerComponent;

    if (this.currentPlayer) this.playerService.enterFullscreenDevice(this.currentPlayer);
    this.enterFullscreenVideo.emit(this.isVideoEnterFullscreen);

    this.slider.params.noSwipingSelector = 'button, input, app-media-player';
    this.slider.update();
  }

  async onExitFullscreen() {
    this.isVideoEnterFullscreen = false;
    document.body.classList.remove(this.CSS_CLASSES.PAGE);
    this.currentPlayer = this.mediaPlayer['_results'][this.multimediaCarousel.positionPlayer] as MediaPlayerComponent;
    if (this.currentPlayer) this.playerService.exitFullscreenDevice(this.currentPlayer);
    this.enterFullscreenVideo.emit(this.isVideoEnterFullscreen);

    this.slider.params.noSwipingSelector = 'button, input';
    this.slider.update();
  }

  onMultimediaError(evt, data) {
    const error = evt.detail.plyr.media.error || {};
    this.logger.debug('Media player has errors: ', error);
    this.isLoadingFile = false;
    this.isReadyVideo = false;
    if (error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
      let msg = this.messages['ERRORS_TOASTS.GENERIC_MSG'];
      if (!data.url) {
        msg = this.messages['ERRORS_TOASTS.INVALID_CONTENT_URL'];
      }
      this.alertService.showError(this.messages['ERRORS_TOASTS.ERROR_TITLE'], msg);
    }
  }

  ngOnDestroy() {
    if (this.multimediaCarousel) {
      this.unsubscribePlatformEvents();
    }
    this.slider.destroy(true, true);
  }

  emitFullScreen(event: Event) {
    event.stopPropagation();
    if (this.multimediaCarousel) {
      this.unsubscribePlatformEvents();
      this.multimediaCarousel = this.updateDataVideoPlayer().actual;
      this.auxUpdateCarrousel = this.updateDataVideoPlayer().allVideos;
      this.pauseCurrentPlayerPlaying();
      this.playerService.isMultimediaCarrouselFullScreen = true;
      this.setFullscreenCarousel.emit({ actual: this.multimediaCarousel, allVideos: this.auxUpdateCarrousel });
    } else {
      this.playerService.isMultimediaCarrouselFullScreen = false;
      this.setFullscreen.emit();
    }
  }

  pauseCurrentPlayerPlaying() {
    if (this.mediaPlayer) {
      this.mediaPlayer['_results'].forEach((videoElem: MSafeAny) => {
        videoElem.pausePlayer();
      });
      this.isAppOnPause = true;
    }
  }

  updateDataVideoPlayer(): MSafeAny {
    if (this.mediaPlayer) {
      const actualPosition = this.getActiveIndex();
      const currentSlider = this.image[actualPosition];
      let positionPlayer = 0;
      this.auxUpdateCarrousel = [];
      const videoConfig = this.mediaPlayer['_results'].filter((videoElem: MSafeAny, index: number) => {
        this.auxUpdateCarrousel.push({
          currentTime: videoElem.currentTimePlayer(),
          isPlaying: videoElem.isPlayingVideo(),
          videoId: videoElem.video.id_video,
          muted: videoElem.mutedPlayer(),
          positionPlayer: index,
          mediaPlayer: videoElem,
          isCurrentIndexSlider: actualPosition
        });
        if (currentSlider.type === 'video' && videoElem.video.id_video === currentSlider.id_video) {
          positionPlayer = index;
          return videoElem;
        }
      });
      if (videoConfig.length > 0) {
        this.multimediaCarousel.currentTime = videoConfig[0].currentTimePlayer();
        this.multimediaCarousel.isPlaying = videoConfig[0].isPlayingVideo();
        this.multimediaCarousel.videoId = videoConfig[0].video.id_video;
        this.multimediaCarousel.muted = videoConfig[0].mutedPlayer();
        this.multimediaCarousel.positionPlayer = positionPlayer;
        this.multimediaCarousel.mediaPlayer = videoConfig[0];
        this.multimediaCarousel.isCurrentIndexSlider = actualPosition;
      }
      return { actual: this.multimediaCarousel, allVideos: this.auxUpdateCarrousel };
    }
  }

  getActiveIndex(): number {
    return this.slider.activeIndex;
  }

  slideTo(index: number, speed = 300): void {
    this.slider.slideTo(index, speed);
    this.setArrowsFullScreen();
  }

  setSwiping(lock: boolean): void {
    this.slider.allowTouchMove = this.image.length > 1 ? lock : false;
  }

  hideArrows(): void {
    if (this.prevBtn && this.nextBtn) {
      this.renderer.addClass(this.prevBtn.nativeElement, 'swiper-button-hidden');
      this.renderer.addClass(this.nextBtn.nativeElement, 'swiper-button-hidden');
    }
  }

  showArrows(): void {
    if (this.prevBtn && this.nextBtn) {
      this.renderer.removeClass(this.prevBtn.nativeElement, 'swiper-button-hidden');
      this.renderer.removeClass(this.nextBtn.nativeElement, 'swiper-button-hidden');
    }
  }

  zoomIn(): void {
    if (this.slider.zoom.scale <= 1) {
      this.setSwiping(false);
      this.slider.zoom.in();
    }
  }

  zoomOut(): void {
    if (this.slider.zoom.scale > 1) {
      this.slider.zoom.out();
      this.setSwiping(true);
    }
  }

  slideNext(): void {
    if (this.allowLoop && this.isLast()) {
      this.slideTo(0);
    } else {
      this.slider.slideNext();
    }
  }

  slidePrev(): void {
    if (this.allowLoop && this.isFirst()) {
      this.slideTo(this.slider.slides.length - 1);
    } else {
      this.slider.slidePrev();
    }
  }

  checkZoomEvent(scale: number) {
    const isSwipeEnabled = scale <= 1;
    this.setSwiping(isSwipeEnabled);
  }

  showCaption(): boolean {
    return this.allowZoom && this.slider.zoom.scale === 1;
  }

  nextButtonIsHidden(): boolean {
    return Boolean(this.slider) && this.isLast() && !this.allowLoop;
  }

  prevButtonIsHidden(): boolean {
    return Boolean(this.slider) && this.isFirst() && !this.allowLoop;
  }

  private isLast(): boolean {
    return Boolean(this.slider && this.slider.activeIndex === this.slider.slides.length - 1);
  }

  private isFirst(): boolean {
    return Boolean(this.slider && this.slider.activeIndex === 0);
  }

  private getPagination(singleImage: boolean) {
    if (singleImage) {
      return {
        el: undefined
      };
    }

    return {
      el: this.pagination.nativeElement,
      clickable: true
    };
  }

  private setZoomEvents(): void {
    const wheelEventFunc = this.renderer.listen(this.sliderContainer.nativeElement, 'wheel', (event) => {
      if (!this.hiddenSwipeActions) {
        if (event.deltaY > 0) {
          this.zoomIn();
        } else {
          this.zoomOut();
        }
      }
    });

    // @types/swiper does not have this event.
    // @ts-ignore
    this.slider.on('zoomChange', (scale: number) => {
      if (!this.hiddenSwipeActions) this.zoomEvent.next(scale);
    });

    this.slider.on('beforeDestroy', () => {
      if (wheelEventFunc) {
        wheelEventFunc();
      }
    });

    this.zoomEvent$.pipe(debounceTime(300)).subscribe((scale: number) => {
      if (!this.hiddenSwipeActions) this.checkZoomEvent(scale);
    });

    this.slider.on('slideChange', () => {
      if (!this.hiddenSwipeActions) {
        if (this.slider.zoom.scale > 1) {
          this.zoomOut();
        }
      }
      this.setArrowsFullScreen();
    });
  }

  private setArrowsFullScreen(): void {
    if (this.allowZoom) {
      if (this.slider.activeIndex === 0) {
        this.setArrowsFirstSlide();
      } else if (this.slider.activeIndex === this.slider.slides.length - 1) {
        this.setArrowsLastSlide();
      } else {
        this.showArrows();
      }
    }
  }

  private setArrowsFirstSlide(): void {
    if (this.prevBtn && this.nextBtn) {
      this.renderer.addClass(this.prevBtn.nativeElement, 'swiper-button-hidden');
      this.renderer.removeClass(this.nextBtn.nativeElement, 'swiper-button-hidden');
    }
  }

  private setArrowsLastSlide(): void {
    if (this.prevBtn && this.nextBtn) {
      this.renderer.addClass(this.nextBtn.nativeElement, 'swiper-button-hidden');
      this.renderer.removeClass(this.prevBtn.nativeElement, 'swiper-button-hidden');
    }
  }

  private getTranslations() {
    this.translate
      .stream(['ERRORS_TOASTS.ERROR_TITLE', 'ERRORS_TOASTS.INVALID_CONTENT_URL', 'ERRORS_TOASTS.GENERIC_MSG'])
      .subscribe((messages) => (this.messages = messages));
  }
}
