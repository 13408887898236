import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subscription, timer } from 'rxjs';
import { delay, retry, take } from 'rxjs/operators';

import { PtrabSessionService } from '@app/ptrab/services/session/ptrab-session.service';
import { AuditCodes, AuditLevels, AuditMessage } from '@app/shared/models/audit-message/audit-message';
import { Conditions } from '@app/shared/models/conditions/conditions.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { UserAnalytics } from '@app/shared/models/user/analytics-user.model';
import { User } from '@app/shared/models/user/user.model';
import { TokenCollection, TokenHelperService } from '@services/auth/token.helper.service';
import { AppError, ErrorCodes, ErrorMessages, ErrorTypes } from '@services/error/error.model';
import { ErrorService } from '@services/error/error.service';
import { Logger } from '@services/logger/logger.service';
import { NetworkService } from '@services/network/network.service';
import { STORAGE_CONSTANTS } from '@services/storage/storage.const';
import { StorageService } from '@services/storage/storage.service';
import { UserService } from '@services/user/user.service';
import { ENV } from 'src/environments/environment';

import { generateCodeChallenge, generateRandomString } from '@mercadona/core/auth';

import { AuthenticationHelpers } from './auth.helpers';
import { BearerToken, TokenService } from './auth.token.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { ApiUrls } from '../api/api.urls.service';
import { AuditService } from '../audit/audit.service';
import { HttpErrorResponse } from '@angular/common/http';

/* eslint-disable @typescript-eslint/naming-convention */

export enum AUTHENTICATION_STATUS {
  ANONIMOUS = 'ANONIMOUS',
  LOGGED_ADFS = 'LOGGED_ADFS',
  LOGGED_ACTIVO2 = 'LOGGED_ACTIVO2',
  LOGOUT = 'LOGOUT',
  REFRESH = 'REFRESH'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly logger = new Logger('AuthService');

  private loginChangedSubject = new BehaviorSubject<boolean>(false);
  loginChanged$ = this.loginChangedSubject.asObservable();

  private statusSubject = new BehaviorSubject<AUTHENTICATION_STATUS>(AUTHENTICATION_STATUS.ANONIMOUS);
  status$ = this.statusSubject.asObservable();

  private userInfoRequestResetTimer!: Subscription;

  constructor(
    private auditService: AuditService,
    private storageService: StorageService,
    private tokenHelperService: TokenHelperService,
    private tokenService: TokenService,
    private errorService: ErrorService,
    private network: NetworkService,
    private ptrabSessionService: PtrabSessionService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private apiUrls: ApiUrls
  ) {}

  async hasSession() {
    return this.tokenHelperService.hasSession();
  }

  async hasActivo2Session() {
    const token = await this.tokenHelperService.get();
    const userInfo = await this.userService.getStoredUser();
    this.logger.debug('hasToken', token);
    this.logger.debug('hasUserInfo', userInfo);
    return token !== null && userInfo !== null;
  }

  getStatus(): AUTHENTICATION_STATUS {
    return this.statusSubject.getValue();
  }

  isLoggedIn(): boolean {
    return this.loginChangedSubject.getValue();
  }

  sendStatus(newStatus: AUTHENTICATION_STATUS) {
    this.statusSubject.next(newStatus);
    this.logger.debug('statusSubjectValue', this.getStatus());
  }

  async login(adfsRedirectHash: string = '') {
    this.errorService.reset();

    const isAzureSession = StorageService.isAzureSession();
    await this.handleCallback(adfsRedirectHash, isAzureSession);

    if (!this.errorService.hasError()) {
      await this.startLoginProcess(isAzureSession);
    }
  }

  async logout() {
    await this.ptrabSessionService
      .ptrabLogout()
      .pipe(take(1))
      .toPromise()
      .catch((err) => this.logger.error('logout error:', err));

    const userAnalytics = new UserAnalytics(null, false);
    this.analyticsService.setUserProperties(userAnalytics);

    this.resetUserInfoRequestRetry();
    this.sendStatus(AUTHENTICATION_STATUS.LOGOUT);
    const url = await this.getLogoutUrl();
    await this.cleanStorage();
    this.userService.clear();
    this.errorService.reset();
    this.storageService.forceLogin();
    this.storageService.restartUserSessionType();

    setTimeout(() => this.windowLocationReplace(url), 1000);
  }

  setLogin(status: AUTHENTICATION_STATUS) {
    const loginCompleted = status === AUTHENTICATION_STATUS.LOGGED_ACTIVO2;

    this.sendStatus(status);
    this.loginChangedSubject.next(loginCompleted);
  }

  logoutComplete() {
    this.sendStatus(AUTHENTICATION_STATUS.ANONIMOUS);
  }

  cleanStorage() {
    return this.storageService.removeItems([
      STORAGE_CONSTANTS.TOKEN,
      STORAGE_CONSTANTS.TOKEN_HINT,
      STORAGE_CONSTANTS.EXPIRATION,
      STORAGE_CONSTANTS.REFRESH_TOKEN_EXPIRATION,
      STORAGE_CONSTANTS.USER,
      STORAGE_CONSTANTS.USER_HOME,
      STORAGE_CONSTANTS.LOGIN_TYPE,
      STORAGE_CONSTANTS.REFRESH_TOKEN,
      STORAGE_CONSTANTS.REDIRECT_INTERNAL_ROUTE,
      STORAGE_CONSTANTS.PAYSLIPS_CURRENT_TAB,
      STORAGE_CONSTANTS.IRPF_CURRENT_TAB,
      STORAGE_CONSTANTS.CERTIFICATES_CURRENT_TAB,
      STORAGE_CONSTANTS.FORCE_LOGIN,
      STORAGE_CONSTANTS.SEARCH_PARAMETERS,
      STORAGE_CONSTANTS.MYEXAMPLES_TAB,
      STORAGE_CONSTANTS.EXAMPLES_FILTER,
      STORAGE_CONSTANTS.RESPONSE_AS_OTHER_USER,
      STORAGE_CONSTANTS.PUBLICATIONS_SEGMENTS_TAGS,
      STORAGE_CONSTANTS.PUBLICATIONS_SEGMENT_TAG_SELECT,
      STORAGE_CONSTANTS.SCHEDULE_CURRENT_TAB,
      STORAGE_CONSTANTS.PERSONAL_DATA_CURRENT_TAB,
      STORAGE_CONSTANTS.NOTIFICATIONS_CURRENT_TAB,
      STORAGE_CONSTANTS.SIGNED_TIMETABLES_FILTER,
      STORAGE_CONSTANTS.FIRST_MOT_SCHEDULE,
      STORAGE_CONSTANTS.VACATIONS_YEAR,
      STORAGE_CONSTANTS.LOGISTIC_CALENDAR_YEAR,
      STORAGE_CONSTANTS.CHECK_OFFICE_SERVICE_SELECTED,
      STORAGE_CONSTANTS.REDIRECT_PAGE_AFTER_PTRAB_LOGIN,
      STORAGE_CONSTANTS.OFFICE_FLOOR
    ]);
  }

  async renewToken(tokenCollection: TokenCollection) {
    // Check connection before and reject if no connection

    const isOnline = await this.network.isOnline();
    if (!isOnline) {
      this.logger.error('No connection available to perform renewToken request');
      return Promise.reject('No connection available');
    }

    if (!tokenCollection.token) {
      Promise.reject(`expiredToken: ${tokenCollection.token}`);
    }

    this.sendStatus(AUTHENTICATION_STATUS.REFRESH);

    try {
      const isAzureSession = StorageService.isAzureSession();
      const newTokenData = await this.tokenService.renewToken(tokenCollection, isAzureSession);
      this.logger.debug('newTokenData', newTokenData);

      await this.auditService.push(
        new AuditMessage({
          level: AuditLevels.Info,
          message: `renewToken: refreshToken success.`,
          status_code: AuditCodes.Success,
          app_component: 'AuthService'
        }),
        false
      );

      await this.storeUserLoginFromToken(newTokenData);
      await this.auditService.flush();
      this.sendStatus(AUTHENTICATION_STATUS.LOGGED_ACTIVO2);

      return Promise.resolve(newTokenData);
    } catch (error) {
      return this.handleRenewTokenError(error);
    }
  }

  async initUserInfo() {
    this.resetUserInfoRequestRetry();
    if (this.getStatus() !== AUTHENTICATION_STATUS.LOGOUT) {
      this.doInitUserInfo();
    }
  }

  windowLocationReplace(url: string) {
    return window.location.replace(url);
  }

  private async handleRenewTokenError(error: MSafeAny) {
    const errorMessage = error && error.message ? error.message : error;

    this.auditService.push(
      new AuditMessage({
        level: AuditLevels.Critical,
        message: `handleRenewTokenError: refreshToken error: ${JSON.stringify(errorMessage)}`,
        status_code: AuditCodes.Error,
        app_component: 'AuthService'
      })
    );

    let errorType: ErrorTypes;
    if (error.status === ErrorCodes.MAINTENANCE) {
      this.logger.debug('Maintenance mode');
      errorType = ErrorTypes.MAINTENANCE_MODE;
    } else if (error.status === ErrorCodes.BAD_REQUEST || errorMessage === ErrorMessages.SESSION_REFRESH_REQUIRED) {
      this.logger.error('renewToken error. It seems refresh token is invalid', error);
      this.tokenHelperService.remove();
      errorType = ErrorTypes.REFRESH_TOKEN;
    } else {
      this.logger.error('renewToken error', error);
      this.sendStatus(AUTHENTICATION_STATUS.ANONIMOUS);
      errorType = ErrorTypes.GET_REFRESH_TOKEN;
    }
    const appError = new AppError(errorType, errorMessage);
    this.errorService.add(appError);

    this.tokenService.resetRenewPromise();
    return Promise.reject(appError);
  }

  private async getLogoutUrl() {
    const token = await this.tokenHelperService.get();

    let urlNavigate = '/';

    if (token) {
      const isAzureSession = StorageService.isAzureSession();

      if (isAzureSession) {
        urlNavigate = this.#getAzureLogoutUri();
      } else {
        urlNavigate = await this.#getAdfsLogoutUri();
      }
    }

    return urlNavigate;
  }

  async #getAdfsLogoutUri() {
    const idTokenHint = await this.tokenHelperService.getIdTokenHint();
    const logout =
      'post_logout_redirect_uri=' +
      encodeURIComponent(ENV.authentication.postLogoutRedirectUri).concat(
        '&id_token_hint=' + encodeURIComponent(idTokenHint)
      );

    return `${ENV.authentication.instance}/oauth2/logout?${logout}`;
  }

  #getAzureLogoutUri() {
    const logout = 'post_logout_redirect_uri=' + encodeURIComponent(ENV.azure.postLogoutRedirectUri);
    return `${ENV.azure.authority}/${ENV.azure.tenantId}/oauth2/v2.0/logout?${logout}`;
  }

  private async handleCallback(hash: string, isAzure: boolean = false) {
    const response = AuthenticationHelpers.deserialize(hash);
    this.logger.debug('HandleCallback deserialize >>>', response);

    if (this.handleError(response)) {
      return;
    }

    if ('code' in response) {
      this.storageService.cleanForceLogin();
      try {
        const refreshTokenData: BearerToken = await this.tokenService.getRefreshToken(response['code'], isAzure);

        if (refreshTokenData) {
          this.logger.debug('Refresh Token >>>', refreshTokenData);
          await this.storeUserLoginFromToken(refreshTokenData);
          // TODO -> GET AUDIENCE TOKEN HERE
          const tokenCollection = await this.tokenHelperService.getCollection();
          if (tokenCollection.token && !ENV.isMocksMode) {
            this.logger.debug('prepareToken: tokenCollection has token');
            await this.renewToken(tokenCollection);
          }
        }
      } catch (error: MSafeAny) {
        this.#handleRefreshTokenError(error);
      }
    }
  }

  #handleRefreshTokenError(error: MSafeAny) {
    this.logger.error('Faield to get RefreshToken >>>', error);

    if (error.status === ErrorCodes.MAINTENANCE) {
      this.errorService.add(new AppError(ErrorTypes.MAINTENANCE_MODE, error));
      return;
    }

    const errorMessage = error && error.message ? error.message : error;
    this.auditService.push(
      new AuditMessage({
        level: AuditLevels.Critical,
        message: `getRefreshToken error: ${JSON.stringify(errorMessage)}`,
        status_code: AuditCodes.Error,
        app_component: 'AuthService'
      })
    );

    this.addError(ErrorTypes.GET_REFRESH_TOKEN, error.message);
  }

  private handleError(response: MSafeAny) {
    if (!response.error) {
      this.logger.debug('has no errors', response);
      return false;
    }

    this.logger.debug('handle error', response);
    this.addError(ErrorTypes.ERROR_LOGIN_SSO, response.error_description);
    return true;
  }

  private addError(type: ErrorTypes, message: string) {
    this.errorService.add(new AppError(type, message));
  }

  async getCurrentSession() {
    const token = await this.tokenHelperService.get();
    if (!token) {
      this.logger.debug('token not present in getCurrentSession');
      return false;
    }

    this.logger.debug('[cached user] token', token);

    this.setLogin(AUTHENTICATION_STATUS.LOGGED_ADFS);
    return token;
  }

  /**
   * Gets ADFS login URL specific to each environment and platform (Changes depends on platform and forcePrompt param)
   * @param loginHint this param add hint for login form
   * @param isAzure this param checks what type of user is to open a different login page
   * @returns Returns ADFS login URL with specific parameters
   */
  private async getLoginUrl(loginHint: string, isAzure: boolean) {
    let urlNavigate: string;
    let params: URLSearchParams;

    if (isAzure) {
      urlNavigate = this.apiUrls.azure.authorize;
      params = await this.#getAzureUrlParams();
    } else {
      urlNavigate = this.apiUrls.adfs.authorize;
      params = this.#getAdfsUrlParams();
    }

    const forcePrompt = this.storageService.hasForceLogin();
    this.logger.debug('Force Login >>>', forcePrompt);

    if (forcePrompt) {
      params.set('prompt', 'login');
    }

    if (loginHint) {
      params.set('login_hint', loginHint);
    }

    urlNavigate += '?' + params.toString();
    this.logger.info('[AUTH] Navigate url >>>' + urlNavigate);
    return urlNavigate;
  }

  #getAdfsUrlParams(): URLSearchParams {
    return new URLSearchParams({
      response_type: 'code',
      client_id: ENV.authentication.clientId,
      redirect_uri: ENV.authentication.redirectUri,
      // resource: ENV.authentication.resource || '',
      scope: ENV.authentication.scope,
      passwordless: 'false',
      nonce: encodeURIComponent(AuthenticationHelpers.guid())
    });
  }

  async #getAzureUrlParams() {
    const state = generateRandomString();
    const codeVerifier = generateRandomString();
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    // TODO -> do this with clean code
    localStorage.setItem('code_verifier', codeVerifier);

    return new URLSearchParams({
      client_id: ENV.azure.clientId,
      redirect_uri: ENV.azure.redirectUri,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256', // SHA256
      response_type: 'code',
      response_mode: 'query',
      scope: ENV.azure.scope,
      state
    });
  }

  async startLoginProcess(isAzure: boolean = false, loginHint: string = '') {
    if (await this.getCurrentSession()) {
      return;
    }

    StorageService.setAzureSession(isAzure);
    this.windowLocationReplace(await this.getLoginUrl(loginHint, isAzure));
  }

  recoverLogin() {
    this.windowLocationReplace(ENV.authentication.recoverLogin);
  }

  private decodeClaims(token: string) {
    const parsed = token.split('.')[1];
    const decoded = atob(parsed);
    return JSON.parse(decoded);
  }

  private storeUserLoginFromToken(token: BearerToken): Promise<void> {
    return new Promise((resolve, reject) => {
      const tokenData = this.decodeClaims(token.privateToken);
      const itemsToSave = [
        { key: STORAGE_CONSTANTS.TOKEN, value: token.privateToken },
        { key: STORAGE_CONSTANTS.EXPIRATION, value: tokenData.exp }
      ];

      if (token && token.refreshToken) {
        const refreshTokenToSave = { key: STORAGE_CONSTANTS.REFRESH_TOKEN, value: token.refreshToken };
        itemsToSave.push(refreshTokenToSave, {
          key: STORAGE_CONSTANTS.REFRESH_TOKEN_EXPIRATION,
          value: token.refreshTokenExpiration + tokenData.exp
        });
      }

      if (token?.idTokenHint) {
        itemsToSave.push({ key: STORAGE_CONSTANTS.TOKEN_HINT, value: token.idTokenHint });
      }

      this.storageService
        .setItems(itemsToSave)
        .then(() => {
          this.logger.debug('storeUserLoginFromToken:setItems: ok');
          resolve();
        })
        .catch(() => {
          this.logger.error('storeUserLoginFromToken:setItems: ko');
          this.addError(ErrorTypes.STORAGE_USER_LOGIN, 'error saving items in storeUserLogin');
          reject();
        });
    });
  }

  retryIfUnathorized(error: HttpErrorResponse) {
    if (error.status === ErrorCodes.UNAUTHORIZED) {
      return timer(1);
    }

    throw error;
  }

  private async doInitUserInfo() {
    const storedUserInfo = await this.userService.getStoredUser();
    if (storedUserInfo !== null) {
      this.logger.debug(`UserInfo found in storage ${JSON.stringify(storedUserInfo)}`);
      this.userService
        .updateUserConditions()
        .pipe(retry({ count: 1, delay: this.retryIfUnathorized }))
        .subscribe(
          async (user) => {
            this.logger.info('Success UserConditions');
            this.endSuccessInitUserInfo(user as User);
          },
          (error) => {
            this.handleInitUserInfoError(error, false);
          }
        );
      return;
    }

    this.userService.requestUserInfo().then(
      async (userInfo: User) => {
        this.logger.debug(`UserInfo retrieved from user/info: ${JSON.stringify(userInfo)}`);
        this.endSuccessInitUserInfo(userInfo);
      },
      async (error) => {
        this.handleInitUserInfoError(error);
      }
    );
  }

  private async handleInitUserInfoError(error: MSafeAny, fromUserInfo = true) {
    let errorHeader = 'InitUserInfo ERROR, error syncing user conditions';
    let errorMessage = `${errorHeader}, error is: ${JSON.stringify(error.message)}`;
    let errorData = `${errorHeader}`;
    if (fromUserInfo) {
      const userData = await this.storageService.getItems([STORAGE_CONSTANTS.REFRESH_TOKEN, STORAGE_CONSTANTS.TOKEN]);
      errorHeader = 'InitUserInfo ERROR, error calling userInfo';
      errorMessage = `${errorHeader}, error is: ${JSON.stringify(error.message)}`;
      errorData = `${errorHeader}. error DATA is: ${JSON.stringify(userData)}`;
    }

    this.logger.debug(errorMessage);
    await this.auditService.push(
      new AuditMessage({
        level: AuditLevels.Critical,
        message: `handleInitUserInfoError ${errorMessage}`,
        status_code: AuditCodes.Error,
        app_component: 'AuthService'
      })
    );

    this.logger.debug(errorData);
    await this.auditService.push(
      new AuditMessage({
        level: AuditLevels.Critical,
        message: `handleInitUserInfoError ${errorData}`,
        status_code: AuditCodes.Error,
        app_component: 'AuthService'
      })
    );

    const errorGettingInfo = new AppError(ErrorTypes.GETTING_USER_INFO, errorMessage);
    this.network.doIfConnection(() => this.errorService.add(errorGettingInfo));

    this.userInfoRequestResetTimer = of(null)
      .pipe(delay(30000))
      .subscribe(() => {
        this.logger.debug('Automatic InitInfo retry');
        this.initUserInfo();
      });
  }

  private endSuccessInitUserInfo(userInfo: User) {
    this.logger.info('Finalizing InitUserInfo');
    this.checkUserLegalConditions(userInfo);
    this.userService.mergeUser(userInfo);
    this.setLogin(AUTHENTICATION_STATUS.LOGGED_ACTIVO2);
    const userAnalytics = new UserAnalytics(userInfo, true);
    this.analyticsService.setUserProperties(userAnalytics);
  }

  private checkUserLegalConditions(conditions: Conditions) {
    if (!conditions.acceptLegal) {
      this.errorService.add(new AppError(ErrorTypes.TERMS_NOT_ACCEPTED));
    }
  }

  private resetUserInfoRequestRetry() {
    if (this.userInfoRequestResetTimer) {
      this.userInfoRequestResetTimer.unsubscribe();
    }
  }
}
