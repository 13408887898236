<div class="top-content top-content--covered">
  <div *ngIf="isTranscoding; else videoThumb" class="transcoding-video">
    <img class="cover-img" [hidden]="!show" (load)="this.show = true" [src]="thumbnailTranscoding" />
    <img class="media-icon" src="assets/imgs/video-card/play.svg" />
  </div>
  <ng-template #videoThumb>
    <img class="cover-img" [hidden]="!show" (load)="this.show = true" [src]="video.thumbnail" />
    <img class="media-icon" src="assets/imgs/video-card/play.svg" />
  </ng-template>

  <app-card-tooltip *ngIf="isTypeLite"></app-card-tooltip>
  <app-share-content
    *ngIf="video.isShareable() && !isWebview"
    class="share-corner"
    [item]="video"
    [isDetail]="false"
    [analyticsCategory]="analyticsCategory"></app-share-content>

  <div class="inner-content">
    <div class="inline-info">
      <span class="card-title">{{ video.title }}</span>
      <span class="card-date"> {{ cardDate | date : 'dd/MM/yyyy' }} </span>
    </div>
  </div>
</div>

<div class="bottom-content">
  <app-card-info *ngIf="!isTypeLite" [item]="video" [cardType]="type"></app-card-info>
  <app-card-buttons
    *ngIf="hasActionButtons"
    [exampleItem]="video"
    [publishIsDisabled]="publishIsDisabled"></app-card-buttons>
</div>
