import { Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';

import { SliderComponent } from '@app/components/slider/slider.component';
import { Logger } from '@app/services';
import { Image } from '@app/shared/models/multimedia/image.model';
import { AbstractMultimediaItem } from '@app/shared/models/multimedia/multimedia.abstract.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { MultimediaDetailDirective } from '../multimedia-detail.component';

@Component({
  selector: 'app-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.scss', '../multimedia-detail.component.scss']
})
export class ImageDetailComponent extends MultimediaDetailDirective implements OnInit, OnDestroy {
  @ViewChild(SliderComponent) slider!: SliderComponent;
  item!: Image;
  imageNotLoaded = false;
  logger = new Logger('Image Detail');
  photoGallery = {
    items: [],
    render: false
  };
  // eslint-disable-next-line
  photoViewer!: MatDialogRef<MSafeAny> | null;
  isWebView!: boolean;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getImage();
    this.isWebView = this.webViewService.isWebView();
  }

  closeGallery() {
    this.photoGallery.render = false;
  }

  async openImage() {
    this.photoViewer = await this.photoViewerService.create({
      images: this.item.images,
      initialSlideIndex: this.slider.getActiveIndex()
    });

    this.photoViewer?.afterClosed().subscribe((slideIndex) => {
      if (this.item.images.length) {
        this.slider.slideTo(slideIndex, 0);
      }
    });
  }

  getImage() {
    this.getItem()
      .pipe(
        tap((image: AbstractMultimediaItem) => {
          if (this.emptyUrl(image)) {
            this.imageNotLoaded = true;
            this.alertService.showError(
              this.messages['ERRORS_TOASTS.ERROR_TITLE'],
              this.messages['ERRORS_TOASTS.INVALID_CONTENT_URL']
            );
          }
        })
      )
      .subscribe(
        () => this.incrementViews(),
        (err) => {
          this.logger.error(err);
          this.close();
        }
      );
  }

  like() {
    this.sendLike(this.item);
  }

  ngOnDestroy() {
    if (this.photoViewer) {
      this.photoViewer.close();
    }
  }

  private emptyUrl(image: MSafeAny) {
    return !image.url && image.images.length === 0;
  }
}
