import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { DirectivesModule } from '@app/shared/directives/directives.module';
import { ComponentsModule } from '@components/components.module';
import { MenuModule } from '@components/menu/menu.module';
import { SearchDropdownComponent } from '@components/search-dropdown/search-dropdown.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { MPopoverModule } from '@mercadona/components/popover';

import { NotificationAdviceComponent } from './components/notification-advice/notification-advice.component';
import { NotificationsPopoverComponent } from './components/notifications-popover/notifications-popover.component';
import { HeaderComponent } from './header.component';

const components = [
  HeaderComponent,
  SearchDropdownComponent,
  NotificationAdviceComponent,
  NotificationsPopoverComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    MenuModule,
    FormsModule,
    ComponentsModule,
    DirectivesModule,
    MPopoverModule
  ],
  exports: components
})
export class HeaderModule {}
