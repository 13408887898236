import { Injectable } from '@angular/core';

import { PAGES } from '@app/shared/enums/pages/pages.enum';
import { UserHome, UserLoginType } from '@app/shared/interfaces/user/user-login.interface';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { User } from '@app/shared/models/user/user.model';
import { Storage } from '@ionic/storage';
import { STORAGE_CONSTANTS } from '@services/storage/storage.const';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private storage: Storage) {}

  static setWebView(isWebview: boolean = true): void {
    window.sessionStorage.setItem(STORAGE_CONSTANTS.IS_WEB_VIEW, String(isWebview));
  }

  static isWebView(): boolean {
    return window.sessionStorage.getItem(STORAGE_CONSTANTS.IS_WEB_VIEW) === 'true';
  }

  static setWebViewPath(path: string): void {
    window.sessionStorage.setItem(STORAGE_CONSTANTS.WEB_VIEW_PATH, path);
  }

  static getWebViewPath(): string {
    return window.sessionStorage.getItem(STORAGE_CONSTANTS.WEB_VIEW_PATH) || PAGES.MODEL;
  }

  static setDarkMode(darkMode: boolean = true): void {
    window.sessionStorage.setItem(STORAGE_CONSTANTS.DARK_MODE, String(darkMode));
  }

  static isDarkMode(): boolean {
    return window.sessionStorage.getItem(STORAGE_CONSTANTS.DARK_MODE) === 'true';
  }

  static setAzureSession(isAzure: boolean = true): void {
    return localStorage.setItem(STORAGE_CONSTANTS.AZURE_SESSION, String(isAzure));
  }

  static isAzureSession() {
    return localStorage.getItem(STORAGE_CONSTANTS.AZURE_SESSION) === 'true';
  }

  static setLanguageCode(languageCode: string): void {
    window.sessionStorage.setItem(STORAGE_CONSTANTS.LANGUAGE_CODE, languageCode);
  }

  static getLanguageCode(): string | undefined {
    return window.sessionStorage.getItem(STORAGE_CONSTANTS.LANGUAGE_CODE) ?? undefined;
  }

  set(key: string, value: MSafeAny): Promise<MSafeAny> {
    return this.storage.set(key, value);
  }

  get(key: string): Promise<MSafeAny> {
    return this.storage.get(key);
  }

  setItems(items: MSafeAny[]): Promise<void> {
    return Promise.all(items).then((values) => {
      values.forEach((item) => {
        this.storage.set(item.key, item.value);
      });
    });
  }

  getItems(keys: string[]): Promise<MSafeAny> {
    const promises = keys.map((key) => this.storage.get(key));

    return Promise.all(promises).then((values) => {
      const result = {};
      values.forEach((value, index) => {
        result[keys[index]] = value;
      });
      return result;
    });
  }

  remove(key: string) {
    return this.storage.remove(key);
  }

  removeItems(keys: string[]) {
    return Promise.all(keys).then((values) => {
      values.forEach((value) => {
        this.storage.remove(value);
      });
    });
  }

  async getUserData(): Promise<User> {
    const data = await this.getItems([STORAGE_CONSTANTS.USER]).catch((error) => Promise.reject(error));
    return data[STORAGE_CONSTANTS.USER];
  }

  storeUserData(userInfo: User) {
    return this.set(STORAGE_CONSTANTS.USER, userInfo);
  }

  forceLogin() {
    return window.sessionStorage.setItem(STORAGE_CONSTANTS.FORCE_LOGIN, 'true');
  }

  hasForceLogin() {
    return window.sessionStorage.getItem(STORAGE_CONSTANTS.FORCE_LOGIN) !== null;
  }
  restartUserSessionType() {
    return localStorage.removeItem(STORAGE_CONSTANTS.AZURE_SESSION);
  }

  getAppVersion(): Promise<string> {
    return this.storage.get(STORAGE_CONSTANTS.APP_VERSION);
  }

  setAppVersion(appVersion: string): Promise<MSafeAny> {
    return this.storage.set(STORAGE_CONSTANTS.APP_VERSION, appVersion);
  }

  cleanForceLogin() {
    return window.sessionStorage.removeItem(STORAGE_CONSTANTS.FORCE_LOGIN);
  }

  setCheckOfficeService(checkValue?: boolean) {
    return this.storage.set(STORAGE_CONSTANTS.CHECK_OFFICE_SERVICE, checkValue);
  }

  getCheckOfficeService(): Promise<boolean> {
    return this.storage.get(STORAGE_CONSTANTS.CHECK_OFFICE_SERVICE);
  }

  setOfficeSelected(checkValue: number) {
    return this.storage.set(STORAGE_CONSTANTS.CHECK_OFFICE_SERVICE_SELECTED, checkValue);
  }

  getOfficeSelected(): Promise<number> {
    return this.storage.get(STORAGE_CONSTANTS.CHECK_OFFICE_SERVICE_SELECTED);
  }

  setPageToRedirectAfterPtrabLogin(page: string) {
    return this.storage.set(STORAGE_CONSTANTS.REDIRECT_PAGE_AFTER_PTRAB_LOGIN, page);
  }

  setRedirectParamsAfterPtrabLogin(params: MSafeAny) {
    return this.storage.set(STORAGE_CONSTANTS.REDIRECT_PARAMS_AFTER_PTRAB_LOGIN, params);
  }

  getPageToRedirectAfterPtrabLogin(): Promise<string> {
    return this.storage.get(STORAGE_CONSTANTS.REDIRECT_PAGE_AFTER_PTRAB_LOGIN);
  }

  getRedirectParamsAfterPtrabLogin(): Promise<MSafeAny> {
    return this.storage.get(STORAGE_CONSTANTS.REDIRECT_PARAMS_AFTER_PTRAB_LOGIN);
  }

  setUserHome(userHome: UserHome) {
    return this.storage.set(STORAGE_CONSTANTS.USER_HOME, userHome);
  }

  getUserHome(): Promise<UserHome> {
    return this.storage.get(STORAGE_CONSTANTS.USER_HOME);
  }

  setLoginType(loginType: UserLoginType) {
    return this.storage.set(STORAGE_CONSTANTS.LOGIN_TYPE, loginType);
  }

  getLoginType(): Promise<UserLoginType> {
    return this.storage.get(STORAGE_CONSTANTS.LOGIN_TYPE);
  }
}
