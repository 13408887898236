import { Injectable, Injector } from '@angular/core';
import { Observable, Observer, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ENV } from 'src/environments/environment';

import { HeartbeatService } from './heartbeat.service';
import { Logger } from '..';
import { AUTHENTICATION_STATUS, AuthService } from '../auth/auth.service';
import { TokenHelperService } from '../auth/token.helper.service';
import { StorageService } from '../storage';
/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({ providedIn: 'root' })
export class MessageService {
  active = true;
  messages$: Subject<MSafeAny> = new Subject();
  userId!: string | null;
  ws!: WebSocket;

  env = ENV;

  private logger = new Logger('MessageService');
  private statusChangeSubscription!: Subscription;
  private wsEventsSubscription!: Subscription;
  private authService: AuthService;

  private readonly RECONNECT_RETRY = 5000;

  constructor(
    private tokenHelperService: TokenHelperService,
    public storageService: StorageService,
    private heartbeat: HeartbeatService,
    private injector: Injector
  ) {
    this.authService = this.injector.get(AuthService);
  }

  async init(userId: string) {
    if (this.env.name === 'dev' || this.env.name === 'itg') {
      return;
    }

    this.active = true;
    this.userId = userId;
    this.connectToWs();
  }

  disconnect() {
    this.logger.info('Disconnecting message provider');
    this.active = false;
    this.userId = null;
    this.heartbeat.clear();

    if (this.ws) {
      this.ws.close();
    }

    if (this.wsEventsSubscription) {
      this.wsEventsSubscription.unsubscribe();
    }

    if (this.statusChangeSubscription && !this.statusChangeSubscription.closed) {
      return;
    }

    this.statusChangeSubscription = this.authService.status$.subscribe(async () => {
      this.logger.info('status change', status);
      if (this.authService.getStatus() === AUTHENTICATION_STATUS.LOGGED_ACTIVO2) {
        const userInfo = await this.storageService.getUserData();
        this.init(userInfo.userid);
      }
    });
  }

  private async connectToWs() {
    if (!this.userId || (this.wsEventsSubscription && !this.wsEventsSubscription.closed)) {
      return;
    }

    try {
      const token = await this.getToken();
      this.heartbeat.clear();
      this.ws = this.connect(`${ENV.WS_URL}user/${this.userId}/`, token);
      this.logger.info('WS connecting', this.ws);
    } catch (error) {
      this.disconnect();
      this.logger.error('CONNECT WS', error);
    }
  }

  private connect(url: string, protocols: string | string[]) {
    const ws = new WebSocket(url, protocols);

    ws.onerror = () => {
      this.logger.error('WebSocket connection failed');
    };

    ws.onopen = () => {
      this.heartbeat.heartbeatCheck(ws);
      this.logger.info('websocket connected');
    };

    const wsObservable = new Observable((obs: Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });

    this.wsEventsSubscription = wsObservable.pipe(map((response: MessageEvent): string => response.data)).subscribe(
      (message: string) => {
        this.logger.info('WS MESSAGE', message);
        this.heartbeat.checkHeartbeatResponse(message);
        this.messages$.next(JSON.parse(message));
      },
      (error) => {
        this.logger.error(`ws error subscription ${JSON.stringify(error)}`);
        this.tryReconnect();
      },
      () => {
        this.logger.info('WS complete');
        this.tryReconnect();
      }
    );

    return ws;
  }

  private tryReconnect() {
    if (!this.active) {
      return;
    }

    this.logger.info(`reconnect in ${this.RECONNECT_RETRY} ms`);
    setTimeout(() => {
      this.connectToWs();
    }, this.RECONNECT_RETRY);
  }

  private async getToken(): Promise<MSafeAny> {
    this.logger.info('getToken');
    try {
      const token = await this.tokenHelperService.get();

      if (!token) {
        return Promise.reject('There is no token stored in App');
      }

      if (!this.tokenHelperService.isTokenExpired(await this.tokenHelperService.getExpiration())) {
        this.logger.info('token is not expired');
        return Promise.resolve(token);
      }

      this.logger.info('token is expired, trying to renew');
      const newToken = await this.authService.renewToken(token);
      return Promise.resolve(newToken.privateToken);
    } catch (error) {
      this.logger.error(error);
      return Promise.reject('Unable to obtain a valid token');
    }
  }
}
