<div class="top-content top-content--covered wow fadeIn">
  <img class="cover-img" [hidden]="!show" (load)="this.show = true" [src]="news.thumbnail" />
  <div class="inner-content">
    <div class="inline-info" [class.inline-info--source]="!!news.source">
      <span class="card-title-group">
        <span class="card-title">{{ news.title }}</span>
        <span class="source"> {{ news.source }} </span>
      </span>
      <span class="card-date"> {{ news.publication_date | date : 'dd/MM/yyyy' }} </span>
    </div>
  </div>

  <app-share-content [item]="news" [isDetail]="false" [analyticsCategory]="analyticsCategory"></app-share-content>
</div>

<div class="bottom-content">
  <app-card-info [item]="news" [cardType]="type"></app-card-info>
</div>
