import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ModalManager } from '@app/services';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss']
})
export class ContentModalComponent {
  @Input() buttons!: Buttons[];
  @Input() hideClose = false;
  @Input() darkMode!: boolean;
  @Output() closeModal = new EventEmitter();

  constructor(private modalManager: ModalManager) {}

  onCloseIconClick() {
    this.closeModal.emit();
    this.modalManager.dismissMatModal();
  }

  trackByItems(item: MSafeAny) {
    return item;
  }
}
