import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[app-only-integer]'
})
export class OnlyIntegerDirective {
  @Input() applyDirective!: boolean;

  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    if (!this.applyDirective) {
      return;
    }

    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
