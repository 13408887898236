import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { PhotoViewerService } from '@app/services/photo-viewer/photo-viewer.service';
import { Image } from '@app/shared/models/multimedia/image.model';
import { windowResize } from '@app/shared/utils/utils';

import { SliderComponent } from '../slider/slider.component';

@Component({
  selector: 'app-image-detail-slides',
  templateUrl: './image-detail-slides.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./image-detail-slides.component.scss']
})
export class ImageDetailSlidesComponent implements OnChanges {
  @Input() image!: Image;
  @Input() darkMode!: boolean;
  @Input() isWebview!: boolean;

  isSlideMode!: boolean;
  indicatorText!: string;
  slideIndex = 0;

  constructor(private photoViewerService: PhotoViewerService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.image) {
      this.isSlideMode = this.image && this.image.images.length > 1;
      this.setIndicatorText(1);
    }
  }

  slideChanged(index: number) {
    this.slideIndex = index;
    this.setIndicatorText(this.slideIndex);
  }

  setIndicatorText(indicator: number) {
    this.indicatorText = `${indicator}/${this.image.images.length}`;
  }

  async openImage(sliderComp?: SliderComponent) {
    const initialSlideIndex = this.slideIndex;
    const photoViewer = await this.photoViewerService.create({
      images: this.image.images,
      initialSlideIndex,
      isWebview: this.isWebview
    });
    windowResize();
    photoViewer?.afterClosed().subscribe((slideIndex) => {
      if (!sliderComp) {
        return;
      }
      sliderComp.slider.slideTo(slideIndex, 0);
    });
  }

  trackByItems(item: Image) {
    return item;
  }
}
