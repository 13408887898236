import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { of, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { DropdownItem } from '@app/ptrab/shared/models/dropdown';
import { Logger } from '@app/services';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { normalizeString, removeHtmlTagsFromString } from '@shared/utils/utils';

import { InputComponent } from '../input/input.component';

const TWO_MILLISECONDS = 200;

@Component({
  selector: 'app-search-confirm',
  templateUrl: './search-confirm.component.html',
  styleUrls: ['./search-confirm.component.scss']
})
export class SearchConfirmComponent implements OnInit, OnChanges, AfterViewInit {
  private readonly logger: Logger = new Logger('StudySearchComponent');

  @ViewChild(InputComponent) searchInput!: InputComponent;
  searchInputEvent!: EventEmitter<MSafeAny>;
  @Input() arrayData!: DropdownItem[];
  @Input() label!: string;
  @Input() disabled!: boolean;
  @Input() maxLength!: number;
  @Input() reloadArrayData!: Subject<void>;

  @Output() selectOption = new EventEmitter<MSafeAny>();
  @Output() searchClicked = new EventEmitter<string>();

  initialArrayData!: DropdownItem[];
  toggle = false;
  hasBeenOpened = false;

  searchTerm!: string;

  @HostListener('document:click')
  clickout() {
    if (!this.hasBeenOpened) {
      this.toggle = false;
    }
    this.hasBeenOpened = false;
  }

  get disabledButton(): boolean {
    return (
      this.disabled || this.searchTerm?.length === 1 || this.searchTerm?.length === 2 || this.arrayData.length === 1
    );
  }

  ngOnInit(): void {
    this.searchTerm = this.arrayData.find((item) => item.selected === true)?.label as string;

    this.reloadArrayData.subscribe(
      () => (this.searchTerm = this.arrayData.find((item) => item.selected === true)?.label as string)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.arrayData) {
      this.initializeArrays();
      this.unselectItems(this.initialArrayData);
      this.searchTerm = '';

      if (this.arrayData.length === 1) {
        this.selectItem(this.arrayData[0]);
      }
    }
  }

  ngAfterViewInit(): void {
    this.searchInputEvent = this.searchInput.modelChange;
    this.loadPredictiveResults();
  }

  onModelChange(term: string) {
    this.searchTerm = term;
    this.unselectItems(this.arrayData, true);
  }

  private initializeArrays() {
    this.initialArrayData = JSON.parse(JSON.stringify(this.arrayData));
  }

  /* istanbul ignore next */

  // eslint-disable-next-line
  loadPredictiveResults() {
    let value: string;
    this.searchInputEvent
      .pipe(
        debounceTime(TWO_MILLISECONDS),
        switchMap((inputValue: string) => {
          this.arrayData = JSON.parse(JSON.stringify(this.initialArrayData));
          value = inputValue;
          this.showDropdown();
          return of(this.arrayData);
        })
      )
      .subscribe(
        (searchPredictive) => {
          if (searchPredictive) {
            searchPredictive.forEach((record) => {
              if (record.selectable) {
                const found = normalizeString(record.label)
                  .toLowerCase()
                  .indexOf(normalizeString(value).toLowerCase() || normalizeString(record.label).toLowerCase());

                if (found >= 0) {
                  record.label = [
                    record.label.slice(0, found),
                    '<b>',
                    record.label.slice(found, found + value.length),
                    '</b>',
                    record.label.slice(found + value.length)
                  ].join('');
                }
              }
            });
          }
        },
        (error) => this.logger.error(error)
      );
  }

  selectItem(selectedItem: DropdownItem) {
    this.toggle = false;
    if (!selectedItem.selected) {
      this.unselectItems(this.arrayData);
      selectedItem.selected = true;
      const htmlString = this.arrayData.find((item) => item.selected === true)?.label;
      this.searchTerm = removeHtmlTagsFromString(htmlString ?? '');
      this.selectOption.emit(selectedItem.value);
    }
  }

  unselectItems(items: DropdownItem[], emit = false) {
    items.forEach((item) => {
      item.selected = false;
      item.label = removeHtmlTagsFromString(item.label);
    });
    if (emit) this.selectOption.emit();
  }

  showDropdown() {
    this.hasBeenOpened = true;
    this.toggle = true;
  }

  searchItems() {
    this.showDropdown();
    this.searchClicked.emit(this.searchTerm);
  }

  trackByItems(item: DropdownItem) {
    return item;
  }
}
