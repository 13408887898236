import { Logger } from '@services/logger/logger.service';
import { PAGES, ROOT_PAGES } from '@shared/enums/pages/pages.enum';
import { InternalRoute, RouteHelper } from '@shared/helpers/route.helper';
import { isIOS, isMobileWeb } from '@shared/utils/is-tablet.util';

import { MSafeAny } from './shared/models/safe-any/safe-any.model';
import { WebViewService } from './shared/services/webview.service';

export class RootPageHelper {
  private readonly logger = new Logger('RootPageHelper');
  private shareableInternalRoute: InternalRoute | undefined;
  isIOS = isIOS();
  isMobileWeb = isMobileWeb();

  constructor(
    private webViewService: WebViewService,
    private path = '',
    private isLandingWebEnabled = true,
    private isLogged = false
  ) {
    this.shareableInternalRoute = RouteHelper.getShareableInternalRoute(path, this.isMobileWeb, isLogged);
  }

  getInitialInternalRoute(navParams = {}): InternalRoute {
    const internalRoute: MSafeAny = { path: undefined, navParams, redirectAfterLogin: undefined };
    let tryToAccessCodes = false;

    this.logger.debug(`Provided path: '${this.path}'`);

    if (RouteHelper.pageIsPrivacy(this.path)) {
      internalRoute.path = ROOT_PAGES.PRIVACY;
      return internalRoute;
    }

    if (this.isIosEnterpriseCodesPage()) {
      if (this.shouldGoToCodes()) {
        internalRoute.path = PAGES.CODES;
        return internalRoute;
      }

      this.path = '';
      this.shareableInternalRoute = undefined;
      tryToAccessCodes = true;
    }

    if (this.isLoggedAndStart()) {
      internalRoute.path = ROOT_PAGES.AUTHENTICATION;
      return internalRoute;
    }

    if (RouteHelper.isAdfsPostLoginRedirect(this.path)) {
      internalRoute.path = ROOT_PAGES.AUTHENTICATION;
      internalRoute.navParams = { adfsRedirectHash: this.path };
      return internalRoute;
    }

    if (this.shouldRedirectToLanding()) {
      internalRoute.path = ROOT_PAGES.MOBILE_WEB_LANDING;
      if (tryToAccessCodes) {
        internalRoute.navParams = { parent: PAGES.CODES };
      }
      return internalRoute;
    }

    if (this.shouldGoToShareableLink()) {
      return this.shareableInternalRoute as InternalRoute;
    }

    if (!this.isLogged) {
      internalRoute.path = ROOT_PAGES.LOGIN;
    }

    return internalRoute;
  }

  private isLoggedAndStart() {
    return !this.path && this.isLogged;
  }

  private shouldRedirectToLanding() {
    return (
      this.isMobileWeb && this.isLandingWebEnabled && !this.shareableInternalRoute && !this.webViewService.isWebView()
    );
  }

  private shouldGoToShareableLink() {
    return this.shareableInternalRoute && this.shareableInternalRoute.path;
  }

  private isIosEnterpriseCodesPage() {
    const pages = [PAGES.CODES];
    const path = RouteHelper.getPageFromPath(this.path);
    return pages.some((route) => route === path);
  }

  private shouldGoToCodes() {
    this.logger.debug(`shouldGoToCodes -> ${this.isIOS}`);
    return this.isIOS;
  }
}
