import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ErrorMsgEnum } from '@app/components/error-msg/error-msg-enum';
import { Logger } from '@app/services';
import { ErrorCodes } from '@app/services/error/error.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ApiMotUrls } from '@mot/services/api/api.service';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  private logger = new Logger('MotService');
  private messages!: string[];

  private onError = new Subject<ErrorMsgEnum>();
  onError$ = this.onError.asObservable();

  constructor(private apiUrls: ApiMotUrls, private http: HttpClient, private translateService: TranslateService) {
    this.getTranslations();
  }

  getSchedule(): Observable<MSafeAny> /* Observable<MotMonth[]> */ {
    let params = new HttpParams();
    params = params.set('lang', this.translateService.currentLang);
    return this.http.get(this.apiUrls.schedule.list, { params }).pipe(
      // map((rawData: { data: MSafeAny[] }) => rawData.data.map((data) => new MotMonth(data))),
      catchError(this.handleError)
    );
  }

  getMonthlyObservations(year: string, month: string): Observable<MSafeAny> /* Observable<MotMonth[]> */ {
    let params = new HttpParams();
    params = params.set('year', year);
    params = params.set('month', month);
    params = params.set('lang', this.translateService.currentLang);
    return this.http.get(this.apiUrls.schedule.monthly_observations, { params }).pipe(catchError(this.handleError));
  }

  getWeeklyObservations(year: string, month: string, week: string): Observable<MSafeAny> /* Observable<MotMonth[]> */ {
    let params = new HttpParams();
    params = params.set('year', year);
    params = params.set('month', month);
    params = params.set('week', week);
    params = params.set('lang', this.translateService.currentLang);

    return this.http.get(this.apiUrls.schedule.weekly_observations, { params }).pipe(catchError(this.handleError));
  }

  private handleError = (error: HttpErrorResponse): Observable<MSafeAny[]> => {
    let errorType;

    switch (error.status) {
      case ErrorCodes.LOCKED:
        errorType = ErrorMsgEnum.SERVICE_LOCKED;
        break;
      case ErrorCodes.SECTION_MAINTENANCE:
        errorType = ErrorMsgEnum.SECTION_MAINTENANCE;
        break;
      default:
        errorType = ErrorMsgEnum.SERVER_ERROR;
        break;
    }

    this.onError.next(errorType);
    this.logger.error(error);

    return of([]);
  };

  private getTranslations() {
    this.translateService.stream(['ERRORS_TOASTS.GENERIC_MSG']).subscribe((messages) => {
      this.messages = messages;
    });
  }
}
