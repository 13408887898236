import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { PersonalDataCvSections } from '@app/activo2/personal-data/personal-data.constants';
import { ApiPtrabUrls } from '@app/ptrab/services';
import { FeedbackTypes } from '@app/ptrab/shared/enums/feedback.enum';
import { TwoFactorAuthorization } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { IDataResponse } from '@app/shared/interfaces/data/data.interface';
import {
  CreateCertificateEmployee,
  EmployeeLanguages,
  Knowledge,
  LanguageMasters,
  LanguageMastersModel
} from '@app/shared/models/personal-data/employee-languages.model';
import {
  PersonalInfo,
  PersonalData,
  UpdatePhone,
  PersonalDataAddress
} from '@app/shared/models/personal-data/personal-data.model';
import {
  CreateQualification,
  Qualification,
  QualificationMaster,
  Studies,
  Study
} from '@app/shared/models/personal-data/studies.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { Logger } from '..';
import { ApiUrls } from '../api/api.urls.service';
import { ErrorCodes } from '../error/error.model';

/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({ providedIn: 'root' })
export class PersonalDataService {
  maintenanceSections: string[] = [];
  errorSections: string[] = [];
  private employeeLanguages!: EmployeeLanguages;
  private studies!: Studies;
  private onError = new Subject<FeedbackTypes>();
  onError$ = this.onError.asObservable();

  private logger: Logger = new Logger('PersonalDataService');

  constructor(private http: HttpClient, private urls: ApiUrls, private ptrabUrls: ApiPtrabUrls) {}

  getPersonalData(): Observable<PersonalData> {
    return this.http
      .get<PersonalInfo>(this.ptrabUrls.personalData.personalInfo)
      .pipe(map((data) => new PersonalData(data)));
  }

  updateEmail(email: string, authorization: TwoFactorAuthorization): Observable<object> {
    return this.http.put(this.ptrabUrls.personalData.email, {
      email,
      authorization
    });
  }

  createPersonalPhone(phoneData: UpdatePhone, authorization: TwoFactorAuthorization): Observable<object> {
    const request = {
      ...phoneData,
      authorization
    };

    return this.http.post(this.ptrabUrls.personalData.phones, request);
  }

  updatePersonalPhone(
    updatePhoneData: UpdatePhone,
    phoneId: string,
    authorization: TwoFactorAuthorization
  ): Observable<object> {
    const request = {
      ...updatePhoneData,
      authorization
    };

    return this.http.put(this.ptrabUrls.personalData.phonesDetail(phoneId), request);
  }

  deletePhone(phoneSectionId: string): Observable<object> {
    return this.http.delete(this.ptrabUrls.personalData.phonesDetail(phoneSectionId));
  }

  updateAddress(address: PersonalDataAddress, authorization: TwoFactorAuthorization): Observable<object> {
    const request = {
      address,
      authorization
    };

    return this.http.put(this.ptrabUrls.personalData.address, request);
  }

  getEmployeeLanguages(): Observable<MSafeAny> {
    return this.http.get<EmployeeLanguages>(this.urls.curriculums.employee_languages).pipe(
      tap((employeeLanguages: EmployeeLanguages) => (this.employeeLanguages = employeeLanguages)),
      catchError((error) => this.handleError(error, PersonalDataCvSections.LANGUAGES))
    );
  }

  getLanguagesMasters(): Observable<LanguageMastersModel> {
    return this.http
      .get<LanguageMasters>(this.urls.curriculums.languages)
      .pipe(map((data) => new LanguageMastersModel(data)));
  }

  getLanguagesData(): Knowledge[] | undefined {
    return this.employeeLanguages?.knowledge;
  }

  getStudiesData(): Study[] | undefined {
    return this.studies?.qualifications;
  }

  getEmployeeStudies(): Observable<MSafeAny> {
    return this.http.get<Studies>(this.urls.qualification.base).pipe(
      tap((studies: Studies) => (this.studies = studies)),
      catchError((error) => this.handleError(error, PersonalDataCvSections.STUDIES))
    );
  }

  getQualificationMaster(): Observable<QualificationMaster> {
    return this.http.get<IDataResponse<QualificationMaster>>(this.urls.qualification.master).pipe(map((d) => d.data));
  }

  getQualifications(
    qualificationZoneId: string,
    educationalLevelId: string,
    name?: string,
    firstPage?: number,
    size?: number
  ): Observable<Qualification> {
    let params = new HttpParams();
    params = params.set('qualification_zone_id', qualificationZoneId);
    params = params.set('educational_level_id', educationalLevelId);
    if (name) params = params.set('qualification_name', name);
    if (firstPage) params = params.set('first_page', firstPage.toString());
    if (size) params = params.set('size_page', size.toString());
    return this.http.get<Qualification>(this.urls.qualification.qualifications, { params });
  }

  createEmployeeCertificate(certificate: CreateCertificateEmployee): Observable<EmployeeLanguages> {
    return this.http.post<EmployeeLanguages>(this.urls.curriculums.employee_languages, certificate).pipe(
      catchError(
        // eslint-disable-next-line
        (error) =>
          // eslint-disable-next-line
          throwError(error)
      )
    );
  }

  updateEmployeeCertificate(certificate: CreateCertificateEmployee): Observable<EmployeeLanguages> {
    return this.http
      .put<EmployeeLanguages>(this.urls.curriculums.employee_language_detail(certificate.language), certificate)
      .pipe(
        catchError(
          // eslint-disable-next-line
          (error) =>
            // eslint-disable-next-line
            throwError(error)
        )
      );
  }

  addStudyToEmployee(qualification: CreateQualification): Observable<Study> {
    return this.http.post<Study>(this.urls.qualification.base, qualification).pipe(
      catchError(
        // eslint-disable-next-line
        (error) =>
          // eslint-disable-next-line
          throwError(error)
      )
    );
  }

  updateEmployeeStudy(qualification: CreateQualification): Observable<Study> {
    return this.http.put<Study>(this.urls.qualification.base, qualification).pipe(
      catchError(
        // eslint-disable-next-line
        (error) =>
          // eslint-disable-next-line
          throwError(error)
      )
    );
  }

  deleteEmployeeCertificate(languageId: string): Observable<object> {
    return this.http.delete(this.urls.curriculums.employee_language_detail(languageId));
  }

  deleteStudy(studyId: string, studyZoneId): Observable<object> {
    const request = {
      body: {
        qualification_id: studyId,
        qualification_zone_id: studyZoneId
      }
    };

    return this.http.delete(this.urls.qualification.base, request);
  }

  private handleError = (error: HttpErrorResponse, section?: string): Observable<MSafeAny[]> => {
    let errorType;

    this.logger.error(error);
    if (section) {
      switch (error.status) {
        case ErrorCodes.LOCKED:
          errorType = FeedbackTypes.SERVICE_LOCKED;
          this.errorSections.push(section);
          break;
        case ErrorCodes.SECTION_MAINTENANCE:
          errorType = FeedbackTypes.SECTION_MAINTENANCE;
          this.maintenanceSections.push(section);
          break;
        default:
          errorType = FeedbackTypes.SERVER_ERROR;
          this.errorSections.push(section);
          break;
      }
    }

    this.onError.next(errorType);

    return of([]);
  };
}
