import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { IrpfViewItem } from './irpf-view.model';

export class IrsView {
  worker: IrpfViewItem;
  spouse: IrpfViewItem;
  dependants: IrpfViewItem[];
  youngBenefit: MSafeAny;

  constructor(view: MSafeAny) {
    this.worker = view.worker;
    this.spouse = view.spouse;
    this.dependants = view.dependants;
    this.youngBenefit = view.youngBenefit;
  }
}
