import { Component, HostListener, Input, OnDestroy } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';

import { EditExampleComponent } from '@app/components/edit-example/edit-example.component';
import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { ExampleConfirmationModalComponent } from '@app/components/modals/example-confirmation-modal/example-confirmation-modal.component';
import { Logger } from '@app/services';
import { AlertService } from '@app/services/alert/alert.service';
import { LoadingService } from '@app/services/loading/loading.service';
import { ModalManager } from '@app/services/modal-manager/modal-manager';
import { ExampleService } from '@app/services/multimedia/example/example.service';
import { NetworkService } from '@app/services/network/network.service';
import { VimeoStatus } from '@app/shared/constants/multimedia/vimeo.constants';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { Example } from '@app/shared/models/multimedia/example.model';
import { VideoExample } from '@app/shared/models/multimedia/video.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-buttons',
  templateUrl: './card-buttons.component.html',
  styleUrls: ['./card-buttons.component.scss']
})
export class CardButtonsComponent implements OnDestroy {
  @Input() exampleItem!: Example;
  @Input() publishIsDisabled!: boolean;
  logger = new Logger('CardButtonsComponent');
  private messages!: string[];

  constructor(
    private exampleService: ExampleService,
    private network: NetworkService,
    private alertService: AlertService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private modalManager: ModalManager
  ) {
    this.getTranslations();
  }

  @HostListener('click', ['$event'])
  onClick = (event: MSafeAny) => {
    event.stopPropagation();
    return false;
  };

  ngOnDestroy() {
    this.modalManager.dismissAllModalIfNoSession();
  }

  removeExample() {
    const title = 'CONFIRMATION_MODAL.DELETE';
    const contents = ['CONFIRMATION_MODAL.DELETE_CONTENT_1', 'CONFIRMATION_MODAL.DELETE_CONTENT_2'];
    const example = this.exampleItem.title;
    const buttons: Buttons[] = [
      {
        text: 'CANCEL',
        type: 'secondary',
        enabled: true,
        onClick: () => {
          this.modalManager.dismissMatModal();
        }
      },
      {
        text: 'CONFIRMATION_MODAL.DELETE_BUTTON',
        type: 'primary',
        enabled: true,
        onClick: () => {
          this.logger.info('Delete Example');
          this.loadingService.show();
          this.exampleService
            .deleteItem(this.exampleItem.id)
            .pipe(finalize(() => this.loadingService.hide()))
            .subscribe(
              () => {
                this.exampleService.reloadExamples();
                this.alertService.showSuccess(
                  this.messages['CONFIRMATION_MODAL.REMOVE_SUCCESS_TITLE'],
                  this.messages['CONFIRMATION_MODAL.REMOVE_SUCCESS_CONTENT'],
                  3000
                );
              },
              () => {
                this.alertService.showError(
                  this.messages['ERRORS_TOASTS.GENERIC_MSG'],
                  this.messages['ERRORS_TOASTS.GENERIC_MSG'],
                  2000
                );
              }
            );
          this.logger.info(this.exampleItem);
          this.modalManager.dismissMatModal();
        }
      }
    ];
    const modalOpts: MatDialogConfig = {
      data: { title, contents, buttons, example },
      disableClose: true
    };

    this.modalManager.openMatModal(ConfirmationModalComponent, modalOpts);
  }

  editExample() {
    this.logger.info('Edit modal');
    this.logger.warn(this.exampleItem);
    const modalOptions: MatDialogConfig = {
      data: { exampleId: this.exampleItem.id },
      disableClose: true,
      panelClass: 'base-modal'
    };
    if (this.exampleItem.isVideo() && (this.exampleItem as VideoExample).isTranscoding()) {
      this.alertService.showInfo(
        this.messages['INFO_TOASTS.INFO_TITLE'],
        this.messages['INFO_TOASTS.TURNING_VIDEO'],
        3000
      );
      return;
    }
    this.modalManager.openMatModal(EditExampleComponent, modalOptions);
  }

  sendToReviewIfConnection() {
    if (this.publishIsDisabled) {
      return;
    }

    this.network.doIfConnection(() => this.openConfirmationModal(this.exampleItem));
  }

  private openConfirmationModal(exampleItem: Example) {
    if ((exampleItem as VideoExample).vimeo_status === VimeoStatus.UPLOADING_ERROR) {
      this.alertService.showWarning(
        this.messages['ERRORS_TOASTS.GENERIC_MSG'],
        this.messages['ERRORS_TOASTS.CANNOT_SEND']
      );
      return;
    }
    const modalOpts: MatDialogConfig = {
      data: { exampleItem },
      disableClose: true,
      panelClass: 'base-modal'
    };
    this.modalManager.openMatModal(ExampleConfirmationModalComponent, modalOpts);
  }

  private getTranslations() {
    this.translate
      .get([
        'CONFIRMATION_MODAL.REVIEW_SUCCESS_TITLE',
        'CONFIRMATION_MODAL.REVIEW_SUCCESS_CONTENT',
        'CONFIRMATION_MODAL.REMOVE_SUCCESS_TITLE',
        'CONFIRMATION_MODAL.REMOVE_SUCCESS_CONTENT',
        'ERRORS_TOASTS.GENERIC_MSG',
        'INFO_TOASTS.INFO_TITLE',
        'INFO_TOASTS.TURNING_VIDEO',
        'ERRORS_TOASTS.CANNOT_SEND'
      ])
      .subscribe((messages) => (this.messages = messages));
  }
}
