import { AfterContentChecked, Component, Input } from '@angular/core';

import { ITDayType } from '@app/mot/components/mot-day/mot-day.interface';
import { BaseComponentDirective } from '@app/shared/bases/base.component';

@Component({
  selector: 'app-caption-leyend',
  templateUrl: './caption-leyend.component.html',
  styleUrls: ['./caption-leyend.component.scss']
})
export class CaptionLeyendComponent extends BaseComponentDirective implements AfterContentChecked {
  @Input() caption!: ITDayType[];
  @Input() popover = true;

  captionContainer!: ITDayType[];
  screenWidth: number;
  selectedCaption!: ITDayType;

  constructor() {
    super();
    this.screenWidth = document.documentElement.clientWidth;
  }

  ngAfterContentChecked(): void {
    const notZoomDevice = this.screenWidth > 374;
    if (!this.captionContainer || notZoomDevice) {
      return;
    }
  }

  getCaptionStyle(caption: ITDayType): object {
    let style = {};

    if (caption.onlyBorder) {
      style = { border: `2px solid ${caption.colour}` };
    } else {
      style = { 'background-color': caption.colour };
    }

    return style;
  }

  selectCaption(caption: ITDayType) {
    this.selectedCaption = caption;
  }

  trackByItems(item: ITDayType) {
    return item;
  }
}
