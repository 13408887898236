<div class="input-container">
  <input
    app-only-integer
    [applyDirective]="integerOnly"
    [placeholder]="placeholder | translate"
    [disabled]="disabled"
    [maxlength]="maxlength"
    [(ngModel)]="model"
    (ngModelChange)="onModelChange()"
    (focus)="onFocus()"
    [readonly]="readonly"
    #input />
  <ng-content></ng-content>
</div>
