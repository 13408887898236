import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { domChanges, getLastDayOfMonth, getMonthName } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';

import { MPopoverDirective } from '@mercadona/components/popover';

/* eslint-disable @typescript-eslint/naming-convention */

export enum DateRanges {
  START = 'start',
  END = 'end'
}

@Component({
  selector: 'app-ptrab-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class RangeSelectComponent implements OnInit, OnChanges {
  @ViewChild('mPopover') popover!: MPopoverDirective;
  @Input() startDate!: Date;
  @Input() endDate!: Date;
  @Input() limitDate: Date = new Date();
  @Input() years = 5;
  @Output() selectOption = new EventEmitter();

  DateRanges = DateRanges;

  monthsNames: string[] = [];

  startDateMonth!: string;
  startDateYear!: number;
  endDateMonth!: string;
  endDateYear!: number;
  minStartDate!: string;
  maxEndDate!: string;
  minEndDate!: string;
  maxStartDate!: string;
  firstAvailableYear!: number;

  startIsoDate!: string;
  endIsoDate!: string;

  constructor(private translate: TranslateService, private datePipe: DatePipe) {}

  async ngOnInit() {
    this.setDate();
    this.getDatePickerTranslations();
    await domChanges();
    this.updateParentComponent();
  }

  async ngOnChanges() {
    this.setDate();
    await domChanges();
    this.updateParentComponent();
  }

  setDate() {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    this.startDate = this.startDate || today;
    this.endDate = this.endDate || today;
    this.firstAvailableYear = today.getFullYear() - this.years;

    this.minStartDate = this.formatDate(new Date(this.firstAvailableYear, 1, 0)) as string;
    this.maxEndDate = this.formatDate(this.limitDate) as string;
    this.minEndDate = this.formatDate(this.startDate) as string;
    this.maxStartDate = this.maxEndDate;

    this.preselectDate();
    this.setStartDate();
    this.setEndDate();
  }

  formatDate(date: Date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  preselectDate() {
    if (this.startDate) {
      this.minEndDate = this.formatDate(this.startDate) as string;
    }

    if (this.endDate) {
      this.maxStartDate = this.formatDate(this.endDate) as string;
    }
  }

  setStartDate() {
    this.startDate.setDate(1);
    this.startDateMonth = getMonthName(this.startDate.getMonth());
    this.startDateYear = this.startDate.getFullYear();
    this.startIsoDate = this.startDate.toISOString();
  }

  setEndDate() {
    this.endDate.setDate(new Date(this.endDate.getFullYear(), this.endDate.getMonth(), 1).getDate());
    this.endDateMonth = getMonthName(this.endDate.getMonth());
    this.endDateYear = this.endDate.getFullYear();
    this.endIsoDate = this.endDate.toISOString();
  }

  onMonthSelected(date: Date, range: DateRanges) {
    this.popover.hide();
    this.handleDateOnDismiss(date, range);
  }

  setDateToDismiss(date: Date, range: DateRanges) {
    if (range === DateRanges.START) {
      this.startDate = date;
      this.startDate.setDate(1);
      this.startDateMonth = getMonthName(this.startDate.getMonth());
      this.startDateYear = this.startDate.getFullYear();
    } else {
      this.endDate = date;
      this.endDate.setDate(getLastDayOfMonth(this.endDate.getFullYear(), this.endDate.getMonth()));
      this.endDateMonth = getMonthName(this.endDate.getMonth());
      this.endDateYear = this.endDate.getFullYear();
    }

    this.updateParentComponent();
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  updateParentComponent() {
    const startDateLabel = this.startDateMonth;
    const endDateLabel = this.endDateMonth;
    const labelsObj = { startDate: startDateLabel, endDate: endDateLabel };

    this.selectOption.emit({ startDate: this.startDate, endDate: this.endDate, labels: labelsObj });
  }

  getDatePickerTranslations() {
    this.translate
      .get([
        'EMPLOYEE_PORTAL.MONTHS.JANUARY',
        'EMPLOYEE_PORTAL.MONTHS.FEBRUARY',
        'EMPLOYEE_PORTAL.MONTHS.MARCH',
        'EMPLOYEE_PORTAL.MONTHS.APRIL',
        'EMPLOYEE_PORTAL.MONTHS.MAY',
        'EMPLOYEE_PORTAL.MONTHS.JUNE',
        'EMPLOYEE_PORTAL.MONTHS.JULY',
        'EMPLOYEE_PORTAL.MONTHS.AUGUST',
        'EMPLOYEE_PORTAL.MONTHS.SEPTEMBER',
        'EMPLOYEE_PORTAL.MONTHS.OCTOBER',
        'EMPLOYEE_PORTAL.MONTHS.NOVEMBER',
        'EMPLOYEE_PORTAL.MONTHS.DECEMBER'
      ])
      .subscribe((translationsMonths: string[]) => {
        this.monthsNames = Object.values(translationsMonths);
      });
  }

  private handleDateOnDismiss(date: Date, range: DateRanges) {
    if (!date) {
      return;
    }
    this.setDateToDismiss(date, range);
  }
}
