<app-data-section *ngIf="!singleCompany; else companyInfo" [title]="withholding.company.name">
  <ng-container *ngTemplateOutlet="companyInfo"></ng-container>
</app-data-section>

<ng-template #companyInfo>
  <app-data-item
    [label]="'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.BASE' | translate"
    value="{{ withholding.base | number : '.2' }}%"></app-data-item>
  <ng-container *ngIf="!withholding.voluntary">
    <app-data-item
      [label]="'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.VOLUNTARY' | translate"
      [value]="'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.NO_VOLUNTARY_WITHHOLDING' | translate"
      [actionText]="'EMPLOYEE_PORTAL.REQUEST' | translate"
      (actionClick)="onRequestClick()"></app-data-item>
    <p class="applicable-withholding-message u-p3 u-medium-grey">
      {{ 'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.REQUEST.APPLICABLE_MESSAGE' | translate }}
    </p>
  </ng-container>
  <ng-container *ngIf="withholding.voluntary">
    <app-data-item
      [label]="'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.VOLUNTARY' | translate"
      value="{{ withholding.voluntary | number : '.2' }}%"
      [actionText]="'EMPLOYEE_PORTAL.MODIFY' | translate"
      (actionClick)="onEditClick()"></app-data-item>
    <div class="applicable-withholding-message">
      <p class="u-p3 u-medium-grey">
        {{ 'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.MODIFY.APPLICABLE_MESSAGE_1' | translate }}
      </p>
      <br />
      <p class="u-p3 u-medium-grey">
        {{ 'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.MODIFY.APPLICABLE_MESSAGE_2' | translate }}
      </p>
      <br />
      <span class="u-ls remove-message" (click)="onRemoveClick()">{{
        'EMPLOYEE_PORTAL.SALARY_WITHHOLDING.MODIFY.APPLICABLE_MESSAGE_3' | translate
      }}</span>
    </div>
  </ng-container>
</ng-template>
