<form class="form" [formGroup]="form">
  <!-- ########### Title ########### -->
  <app-input-wrapper
    [label]="'MULTIMEDIA_FORM.PLACEHOLDER_LABEL'"
    [errorMsg]="'REQUIRED.REQUIRED_FIELD'"
    [hasError]="titleControl.invalid && titleControl.dirty">
    <app-richtext-editor
      [class.richtext-editor--error]="titleControl.invalid && titleControl.dirty"
      [maxLength]="25"
      [multiLine]="false"
      [placeholder]="'MULTIMEDIA_FORM.PLACEHOLDER_LABEL' | translate"
      [emojiPickerEnabled]="false"
      formControlName="title">
    </app-richtext-editor>
  </app-input-wrapper>

  <!-- ########### Multimedia ########### -->
  <app-multimedia-manager *ngIf="type === 'text'; else previews" (addMultimedia)="addMultimedia($event)">
  </app-multimedia-manager>

  <!-- ########### Description ########### -->
  <app-input-wrapper id="description" [label]="'MULTIMEDIA_FORM.ADD_TEXT'" class="spaced">
    <app-richtext-editor
      [showToolbar]="true"
      [maxLength]="2000"
      [placeholder]="'MULTIMEDIA_FORM.ADD_TEXT' | translate"
      [minHeight]="140"
      [emojiPickerEnabled]="false"
      [multiLine]="true"
      formControlName="description">
    </app-richtext-editor>
  </app-input-wrapper>
</form>

<ng-template #previews>
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'image'">
      <ng-container *ngTemplateOutlet="imagePreview"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'video'">
      <ng-container *ngTemplateOutlet="videoPreview"></ng-container>
    </ng-container>
  </ng-container>
  <span class="body-files-message">{{ 'INFO_MESSAGE.BODY_FILES' | translate }}</span>
</ng-template>

<!-- ########### Templates ########### -->
<ng-template #imagePreview>
  <app-example-form-images
    [images]="images"
    (imagesChange)="updateImages($event)"
    [maxFiles]="MAX_FILES"></app-example-form-images>
</ng-template>

<ng-template #videoPreview>
  <div class="detail__player">
    <app-video-preview [previewVideo]="multimedia.preview | safe : 'url'" (remove)="removeMultimedia()">
    </app-video-preview>
  </div>
</ng-template>
