import { Injectable } from '@angular/core';
import { finalize } from 'rxjs';

import { ConfirmationModalComponent } from '@app/components/modals/confirmation-modal/confirmation-modal.component';
import { AlertService } from '@app/services';
import { FileFetchHelper } from '@app/shared/helpers/file-fetch/file-fetch.helper';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { TranslateService } from '@ngx-translate/core';
import { ImageCompressionService } from '@services/files/image-compression/image-compression.service';
import { LoadingService } from '@services/loading/loading.service';
import { Logger } from '@services/logger/logger.service';
import { ExtensionsManager } from '@shared/extensions/extensions-manager';

import { AnalyticsService } from '../analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics, PagesAnalytics } from '../analytics/models/analytics.enum';
import { ModalManager } from '../modal-manager/modal-manager';
import { NetworkService } from '../network/network.service';
import { UserService } from '../user/user.service';

/* eslint-disable @typescript-eslint/naming-convention */

enum AvatarExtensions {
  IMAGE = 'IMAGE'
}

const AVATAR_VALID_EXTENSIONS = {};
AVATAR_VALID_EXTENSIONS[AvatarExtensions.IMAGE] = ['jpg', 'jpeg'];

const AVATAR_MAX_SIZE_MB = 0.05;

@Injectable({ providedIn: 'root' })
export class ProfileAvatarService {
  private logger = new Logger('ProfileAvatarProvider');
  private extensionsManager = new ExtensionsManager<AvatarExtensions>(AVATAR_VALID_EXTENSIONS);
  private readonly imageScale = 300;

  constructor(
    private imageCompressionService: ImageCompressionService,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private translate: TranslateService,
    private modalManager: ModalManager,
    private userService: UserService,
    private network: NetworkService,
    private analyticsService: AnalyticsService
  ) {
    this.extensionsManager.setExtensions(AvatarExtensions.IMAGE);
  }

  getAcceptedFileExtensions(): string {
    return this.extensionsManager.getParsedExtensions();
  }

  async handleDesktopImage(file: File): Promise<void> {
    if (!file) {
      return Promise.reject({ error: 'File not selected...', type: 'None' });
    }

    if (!this.extensionsManager.isValidExtension(file.name)) {
      return Promise.reject({ msgKey: 'EXTENSION', error: 'Error with file extension', type: 'Alert' });
    }

    this.loadingService.show();

    this.logger.debug(`file is "${file.name}"`);
    this.imageCompressionService
      .compress(file, {
        maxSizeMB: AVATAR_MAX_SIZE_MB,
        maxWidthOrHeight: this.imageScale
      })
      .then((fileResult: File) => {
        this.confirmAvatarEdit(fileResult);
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject({ error, type: 'Alert' });
      })
      .finally(() => this.loadingService.hide());
  }

  triggerDesktopFlow(file: File) {
    this.handleDesktopImage(file).catch((err) => {
      const error = err;
      this.logger.error(error);
      const msgKey = error.msgKey || 'ERROR_BODY';
      const errorType = error.type || 'Error';

      const errorAction = {
        Alert: () => this.alertService.showWarning(this.getMessage('WARNING'), this.getMessage(msgKey), 3000),
        Error: () => this.alertService.showError(this.getMessage('ERROR_TITLE'), this.getMessage(msgKey), 3000)
      };

      errorAction[errorType]();

      throw new Error(error.error);
    });
  }

  deleteAvatar() {
    this.loadingService.show();
    this.userService
      .deleteAvatar()
      .pipe(
        finalize(() => {
          this.loadingService.hide();
          this.modalManager.dismissMatModal();
        })
      )
      .subscribe({
        next: () => {
          this.onDeleteAvatar();
        },
        error: () => {
          this.alertService.showError(this.getMessage('ERROR_TITLE'), this.getMessage('DELETE_PHOTO_ERROR'), 3000);
        }
      });
  }

  confirmAvatarEdit(file: File) {
    this.logger.debug('file', file);

    const title = 'PROFILE.EDIT_AVATAR.TITLE';
    const contents = ['PROFILE.EDIT_AVATAR.CONFIRM_MODAL_1', 'PROFILE.EDIT_AVATAR.CONFIRM_MODAL_2'];
    const buttons: Buttons[] = [
      {
        text: 'CANCEL',
        type: 'secondary',
        enabled: true,
        onClick: () => {
          this.modalManager.dismissMatModal();
          return this.network.redirectIfOffline();
        }
      },
      {
        text: 'PROFILE.EDIT_AVATAR.CONFIRM_BUTTON',
        type: 'primary',
        enabled: true,
        onClick: async () => {
          if (!this.network.hasConnection()) {
            this.modalManager.dismissMatModal();
            return this.network.redirectIfOffline();
          }

          this.loadingService.show();

          this.userService
            .editAvatar(file)
            .pipe(
              finalize(() => {
                this.analyticsService.sendEvent(CategoriesAnalytics.USER_MENU, {
                  [ActionsAnalytics.CLICKACTION]: ActionsAnalytics.USER_PIC_EDITED
                });

                this.loadingService.hide();
                this.modalManager.dismissMatModal();
              })
            )
            .subscribe({
              next: () => {
                this.changePhotoOnStorage(file);
              },
              error: (error) => {
                this.onErrorEditAvatar(error);
              }
            });
        }
      }
    ];

    this.modalManager.openMatModal(ConfirmationModalComponent, {
      data: { title, contents, buttons, pageAnalytics: PagesAnalytics.MODAL_CHANGE_AVATAR_IMAGE },
      panelClass: ['media-content-modal', 'base-modal--confirmation'],
      disableClose: true
    });
  }

  private async changePhotoOnStorage(file: File) {
    const b64 = await FileFetchHelper.getB64FromBlob(file);
    await this.userService.storeAvatar(b64);
    this.alertService.showSuccess(this.getMessage('SUCCESS_TITLE'), this.getMessage('SUCCESS_BODY'), 3000);
  }

  private onDeleteAvatar() {
    this.userService.storeAvatar('');
    this.alertService.showSuccess(this.getMessage('SUCCESS_TITLE'), this.getMessage('DELETE_PHOTO_SUCCESS'), 3000);
  }

  private onErrorEditAvatar(error) {
    this.logger.error(error);
    const errorMsg = error?.error?.message || this.getMessage('ERROR_BODY');
    this.alertService.showError(this.getMessage('ERROR_TITLE'), errorMsg, 3000);
  }

  private getMessage(subkey: string) {
    const key = `PROFILE.EDIT_AVATAR.${subkey}`;
    return this.translate.instant(key);
  }
}
