import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { InputComponent } from '@app/components/input/input.component';
import { DropdownItem } from '@app/ptrab/shared/models/dropdown';
import { HighlighterService } from '@app/services/highlighter/highlighter.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

@Component({
  selector: 'app-office-search',
  templateUrl: './office-search.component.html',
  styleUrls: ['./office-search.component.scss']
})
export class OfficeSearchComponent implements OnChanges, AfterViewInit {
  @ViewChild(InputComponent) searchInput!: InputComponent;
  searchInputEvent!: EventEmitter<MSafeAny>;
  @Input() offices!: DropdownItem[];
  @Input() label!: string;
  @Input() showArrows = false;

  @Output() selectOption = new EventEmitter<MSafeAny>();

  previousSelection: DropdownItem | undefined;
  toggle = false;

  constructor(private element: ElementRef, public highlighterService: HighlighterService) {}

  @HostListener('document:click', ['$event'])
  /* istanbul ignore next */
  clickout(ev) {
    if (this.toggle && !this.element.nativeElement.contains(ev.target)) {
      this.toggle = false;

      if (this.previousSelection) {
        this.previousSelection.selected = true;
        this.highlighterService.setSearchTerm(this.previousSelection.label);
      }

      this.highlighterService.initializeArrays(this.offices);
    }
  }

  get officesFiltered() {
    return this.highlighterService.filteredArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.offices) {
      this.highlighterService.initializeArrays(this.offices);
      const selectedItem = this.offices.find((item) => item.selected);

      if (selectedItem) {
        this.previousSelection = selectedItem;
        this.highlighterService.setSearchTerm(this.previousSelection.label);
      } else {
        this.highlighterService.setSearchTerm('');
      }
    }
  }

  ngAfterViewInit(): void {
    this.searchInputEvent = this.searchInput.modelChange;
    this.highlighterService.loadPredictiveResults(this.searchInputEvent);
  }

  onModelChange(term: string) {
    this.highlighterService.searchTerm = term;
  }

  selectItem(selectedItem: DropdownItem) {
    this.previousSelection = selectedItem;
    this.toggle = false;
    this.selectOption.emit(this.highlighterService.selectItem(selectedItem));
  }

  showDropdown() {
    this.toggle = true;
    this.highlighterService.setSearchTerm('');
    this.highlighterService.filteredArray = this.highlighterService.array;
    if (!this.highlighterService.filteredArray.length) {
      this.highlighterService.search();
    }
  }

  onCloseIconClick(event: Event) {
    event.stopPropagation();
    this.highlighterService.setSearchTerm('');
    this.highlighterService.unselectItems(this.offices);
    this.selectOption.emit(-1);
  }

  trackByItems(item: DropdownItem) {
    return item;
  }
}
