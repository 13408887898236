import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';

import { MediaPlayerComponent } from '@app/components/media-player/media-player.component';
import { isIOS } from '@shared/utils/is-tablet.util';

@Injectable({
  providedIn: 'root'
})
export class MediaPlayerService {
  private playSource = new Subject<MediaPlayerComponent>();
  private stopSource = new Subject();
  private pauseSource = new Subject<MediaPlayerComponent>();
  private renderer: Renderer2;

  playerOnFullscreen!: MediaPlayerComponent | null;
  playVideo$ = this.playSource.asObservable();
  stopVideo$ = this.stopSource.asObservable();
  pauseVideo$ = this.pauseSource.asObservable();
  isMultimediaCarrouselFullScreen!: boolean;
  doScrollToPoint!: boolean;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  doScrollToContent(doScroll: boolean) {
    this.doScrollToPoint = doScroll;
  }

  play(player: MediaPlayerComponent) {
    this.playSource.next(player);
  }

  stop() {
    this.stopSource.next(null);
  }

  pauseAllBut(player: MediaPlayerComponent) {
    this.pauseSource.next(player);
  }

  enterFullscreenDevice(player: MediaPlayerComponent) {
    this.playerOnFullscreen = player;
    this.updatePlayerContainerStyles(true);
  }

  exitFullscreenDevice(player: MediaPlayerComponent) {
    if (this.playerOnFullscreen?.getId() !== player.getId()) {
      return;
    }

    this.playerOnFullscreen = null;
  }

  private updatePlayerContainerStyles(isFullscreen: boolean) {
    const containerClassName = 'app-content';
    const className = 'fix-fullscreen-content';

    if (isIOS()) {
      const container = this.playerOnFullscreen?.multimediaElementRef?.nativeElement.closest(containerClassName);

      if (!container) {
        return;
      }

      if (isFullscreen) {
        this.renderer.addClass(container, className);
      } else {
        this.renderer.removeClass(container, className);
      }
    }
  }
}
