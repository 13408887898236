<!-- Google Tag Manager (noscript) -->
<noscript>
  <iframe [src]="analyticsUrl" height="0" width="0" style="display: none; visibility: hidden"></iframe>
</noscript>
<!-- End Google Tag Manager (noscript) -->

<router-outlet id="main"> </router-outlet>

<app-lightbox-cookies *ngIf="showCookies" (action)="manageActionCookies($event)"></app-lightbox-cookies>
<app-alert></app-alert>
