import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { AlertService } from '@app/services';
import { MultimediaFactory } from '@app/shared/models/multimedia/multimedia.factory';
import { ServerPagination } from '@app/shared/models/multimedia/pagination.model';
import { Publication } from '@app/shared/models/multimedia/publication.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { SECTIONS } from '@shared/constants/search/search-results.const';
import { PAGES } from '@shared/enums/pages/pages.enum';
import { HttpOptions } from '@shared/models/http/http.options.model';
import {
  AbstractMultimediaItem,
  AbstractPaginatedMultimediaItemResponse
} from '@shared/models/multimedia/multimedia.abstract.model';
import { ModelResult } from '@shared/models/search/model-result.model';
import {
  SearchHistory,
  SearchParameters,
  SearchResult,
  UnifiedSearchResponse
} from '@shared/models/search/search.model';

import { ApiUrls } from '..';
import { ErrorCodes, ErrorMessages } from '../error/error.model';
import { LanguageService } from '../language/language.service';
import { STORAGE_CONSTANTS, StorageService } from '../storage';

const MAX_RESULTS = 4;

@Injectable({ providedIn: 'root' })
export class SearchService {
  public searchSource = new Subject();
  public search$ = this.searchSource.asObservable();

  private searchParameters = new SearchParameters();

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private urls: ApiUrls,
    private alertService: AlertService,
    private languageService: LanguageService
  ) {}

  setSearchTerm(term: string) {
    this.searchParameters.term = term;
  }

  setSection(section: SECTIONS) {
    this.searchParameters.section = section;
  }

  setSectionFromPage(page: PAGES) {
    switch (page) {
      case PAGES.EXAMPLES:
        this.setSection(SECTIONS.examples);
        break;
      case PAGES.MODEL:
        this.setSection(SECTIONS.model);
        break;
      case PAGES.RESULTS:
        this.setSection(this.searchParameters.section);
        break;
      default:
        this.setSection(SECTIONS.publications);
    }
  }

  setRefererPage(page: PAGES) {
    this.searchParameters.page = page;
  }

  getSearchTerm() {
    return this.searchParameters.term;
  }

  getSection() {
    return this.searchParameters.section;
  }

  getRefererPage() {
    return this.searchParameters.page;
  }

  async storeSearchParameters() {
    await this.storage.set(STORAGE_CONSTANTS.SEARCH_PARAMETERS, this.searchParameters);
  }

  async retrieveParametersFromStorage() {
    this.searchParameters = (await this.storage.get(STORAGE_CONSTANTS.SEARCH_PARAMETERS)) as SearchParameters;
  }

  sendLastSearch(term: string) {
    const searchRecord = { section: this.searchParameters.section, phrase: term };
    return this.http.post(this.urls.term.history, searchRecord);
  }

  getLatestSearches(): Observable<SECTIONS[]> {
    const options = new HttpOptions({
      max_results: MAX_RESULTS,
      section: this.searchParameters.section
    });
    return this.http
      .get<SearchHistory[]>(this.urls.term.history, options)
      .pipe(map((history: SearchHistory[]) => history.map((record) => record.phrase)));
  }

  removeLastestSearches(): Observable<MSafeAny> {
    const request = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        section: this.searchParameters.section
      }
    };
    return this.http.delete(this.urls.term.history, request);
  }

  getSearchResults(initialParams: MSafeAny): Observable<SearchResult> {
    let params = new HttpParams();

    for (const key in initialParams) {
      if (Object.prototype.hasOwnProperty.call(initialParams, key)) {
        const value = initialParams[key];

        if (key !== 'excluded_filter_type') {
          params = params.append(key, value);
        }
      }
    }

    if (initialParams.excluded_filter_type) {
      const filterTypes = initialParams.excluded_filter_type.split(',');
      filterTypes.forEach((filterType) => {
        params = params.append('excluded_filter_type', filterType);
      });
    }

    return this.languageService.getCurrentLanguage().pipe(
      mergeMap((lang) => {
        params = params.append('lang', lang);
        return this.http.get(this.urls.search.base, { params });
      }),
      map((response: UnifiedSearchResponse) => {
        let model;
        let publications;
        let examples;

        if (response.model) {
          model = response.model.map((item) => new ModelResult(item));
        }

        if (response.publications) {
          const paginationPub: ServerPagination = new ServerPagination(response.publications.pagination);
          const itemsPub: Publication[] = response.publications.results.map((item: AbstractMultimediaItem) =>
            MultimediaFactory.createPublication(item)
          );
          publications = new AbstractPaginatedMultimediaItemResponse(itemsPub, paginationPub, 0);
        }

        if (response.examples) {
          const paginationEx: ServerPagination = new ServerPagination(response.examples.pagination);
          const itemsEx = response.examples.results.map((item) => MultimediaFactory.createExample(item));
          examples = new AbstractPaginatedMultimediaItemResponse(itemsEx, paginationEx, paginationEx.lastViewed);
        }

        return new SearchResult({ model, publications, examples });
      })
    );
  }

  getPredictiveResults(params: MSafeAny): Observable<MSafeAny> {
    const options = new HttpOptions({
      ...params,
      max_results: MAX_RESULTS
    });
    return this.http.get(this.urls.term.trends, options).pipe(catchError(this.handleError));
  }

  private handleError = (error: HttpErrorResponse): Observable<MSafeAny[]> => {
    if (error.status === ErrorCodes.SECTION_MAINTENANCE && error.error.code === ErrorMessages.PERM_DEACTIVATED) {
      this.alertService.showError(error.error.message, '');
    }

    return of([]);
  };
}
