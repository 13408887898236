import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { DropdownItem } from '@app/ptrab/shared/models/dropdown';
import { normalizeString, isValidString, removeHtmlTagsFromString } from '@app/shared/utils/utils';

import { Logger } from '../logger';

@Injectable({
  providedIn: 'root'
})
export class HighlighterService {
  array!: DropdownItem[];
  initialArray: DropdownItem[] = [];
  filteredArray: DropdownItem[] = [];
  searchTerm!: string;

  private logger = new Logger('HighlighterService');

  initializeArrays(array: DropdownItem[]) {
    this.array = array;
    this.initialArray = JSON.parse(JSON.stringify(this.array));
  }

  setSearchTerm(value: string) {
    this.searchTerm = value;
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  loadPredictiveResults(searchInputEvent) {
    let value: string;
    searchInputEvent
      .pipe(
        debounceTime(200),
        switchMap((inputValue: string) => {
          this.array = JSON.parse(JSON.stringify(this.initialArray));
          value = inputValue;
          this.search(value);
          if (value) {
            return of(this.filteredArray);
          }
          return of(null);
        })
      )
      .subscribe(
        (searchPredictive) => {
          if (searchPredictive) {
            searchPredictive.forEach((record) => {
              const found = normalizeString(record.label)
                .toLowerCase()
                .indexOf(normalizeString(value).toLowerCase() || normalizeString(record.label).toLowerCase());

              if (found >= 0) {
                record.label = [
                  record.label.slice(0, found),
                  '<b>',
                  record.label.slice(found, found + value.length),
                  '</b>',
                  record.label.slice(found + value.length)
                ].join('');
              }
            });
          }
        },
        (error) => this.logger.error(error)
      );
  }

  search(valueToSearch?) {
    /* istanbul ignore next */
    const termToSearch = valueToSearch
      ? // eslint-disable-next-line
        valueToSearch.replace(/[&\/\\#,()$~%.'":*?<>{}]/g, '').trim()
      : this.searchTerm;

    if (isValidString(termToSearch)) {
      this.filteredArray = this.array.filter((item) =>
        normalizeString(item.label).toLowerCase().includes(normalizeString(termToSearch).toLowerCase())
      );
    } else {
      this.filteredArray = this.array;
    }

    return this.filteredArray;
  }

  selectItem(selectedItem: DropdownItem) {
    this.unselectItems(this.array);
    this.unselectItems(this.initialArray);
    selectedItem.selected = true;
    const htmlString = this.array.find((item) => item.selected === true)?.label;
    this.setSearchTerm(removeHtmlTagsFromString(htmlString as string));

    return selectedItem.value;
  }

  unselectItems(items: DropdownItem[]) {
    items.forEach((item) => {
      item.selected = false;
      item.label = removeHtmlTagsFromString(item.label);
    });
  }
}
