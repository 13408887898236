import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, finalize } from 'rxjs';

import { ModalManager } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ActionsAnalytics, CategoriesAnalytics, PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { LoadingService } from '@app/services/loading/loading.service';
import { PublicationService } from '@app/services/multimedia/publication.service';
import { Buttons } from '@app/shared/models/buttons/buttons';
import { PublicationFilter } from '@app/shared/models/filters/filters';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { removeArrayDuplicates } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';

import { PublicationFilterResponse } from './publication-filter-button/publication-filter-button.component';

@Component({
  selector: 'app-publication-filter',
  templateUrl: './publication-filter.component.html',
  styleUrls: ['./publication-filter.component.scss']
})
export class PublicationFilterComponent implements OnInit {
  selectedFilterIds!: number[];
  allFiltersSelected!: boolean;
  buttons: Buttons[] = [];
  publicationFilters: PublicationFilter[] = [];
  publicationFiltersSelected: number[] = [];
  numResults$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  initialFilterIds: number[] = [];

  selectAllFilter: PublicationFilter = {
    filter_id: -99999,
    filter_name: '',
    filter_image: '',
    result_number: 0
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MSafeAny,
    private publicationService: PublicationService,
    private modalManager: ModalManager,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private loadingService: LoadingService
  ) {
    this.setSelectAllText();
  }

  ngOnInit(): void {
    this.selectedFilterIds = this.data.selectedFilterIds || [];
    this.allFiltersSelected = this.data.allFiltersSelected || false;

    this.loadingService.show();
    this.analyticsService.setPage(CategoriesAnalytics.CONTENT, CategoriesAnalytics.PUBLICATIONS, PagesAnalytics.FILTER);
    this.initialFilterIds = [...this.selectedFilterIds];
    this.publicationFiltersSelected = this.selectedFilterIds;
    this.publicationService
      .getFilters()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((filters) => {
        this.publicationFilters = filters;
        this.mapCheckboxes();
        this.numResults$.next(this.getInitialResults());
        this.selectAllFilter.selected = this.allFiltersSelected;
      });
    this.numResults$.subscribe((numResults) => this.setButtons(numResults));
    this.translateService.onLangChange.subscribe(() => this.setSelectAllText());
  }

  togglePublicationFilter(filter: PublicationFilter) {
    const filterIndex = this.publicationFiltersSelected.findIndex((filterId) => filter.filter_id === filterId);

    if (filterIndex !== -1) {
      this.setNumResults(-filter?.result_number);
      this.publicationFiltersSelected.splice(filterIndex, 1);
      this.selectAllFilter.selected = false;
    } else {
      this.setNumResults(filter.result_number);
      this.publicationFiltersSelected.push(filter.filter_id);
    }

    this.mapCheckboxes();

    if (!filter.subfilters || !filter.subfilters.length) {
      this.checkAllSubcategoriesAreSelected(filter);
    }
  }

  toggleSelectAllFilter() {
    this.deleteFilters(false);

    if (this.selectAllFilter.selected) {
      this.publicationFilters.forEach((filter) => {
        filter.selected = true;
        this.publicationFiltersSelected.push(filter.filter_id);
        this.selectSubcategoriesFromCategory(true, filter);
      });
    }
  }

  mapCheckboxes(unselectAll = false) {
    this.publicationFilters.forEach((filter) => {
      filter.selected = unselectAll
        ? false
        : Boolean(this.publicationFiltersSelected.find((filterId) => filter.filter_id === filterId));
      filter.subfilters?.forEach(
        (subfilter) =>
          (subfilter.selected = unselectAll
            ? false
            : Boolean(this.publicationFiltersSelected.find((filterId) => subfilter.filter_id === filterId)))
      );
    });
  }

  checkAllSubcategoriesAreSelected(selectedFilter: PublicationFilter) {
    const parentFilter = this.publicationFilters.find((pubFilter) =>
      pubFilter.subfilters?.find((subfilter) => subfilter.filter_id === selectedFilter.filter_id)
    );

    if (parentFilter) {
      const parentAlreadySelected = parentFilter.selected;
      parentFilter.selected = parentFilter.subfilters?.every((subfilter) => subfilter.selected);

      if (parentFilter.selected) {
        this.publicationFiltersSelected.push(parentFilter.filter_id);
        this.setNumResults(parentFilter.result_number);
      } else if (parentAlreadySelected) {
        this.setNumResults(-parentFilter.result_number);
      }
    }
  }

  selectSubcategoriesFromCategory(select: boolean, filter: PublicationFilter) {
    if (select) {
      filter.subfilters?.forEach((subfilter) => {
        subfilter.selected = true;
      });

      if (filter.subfilters) {
        this.publicationFiltersSelected.push(...filter.subfilters.map((subfilter) => subfilter.filter_id));
      }
    } else {
      filter.subfilters?.forEach((subfilter) => {
        subfilter.selected = false;
        this.publicationFiltersSelected = this.publicationFiltersSelected.filter(
          (filterId) => filterId !== subfilter.filter_id && filterId !== filter.filter_id
        );
      });
    }

    this.numResults$.next(this.getInitialResults());
    this.publicationFiltersSelected = removeArrayDuplicates(this.publicationFiltersSelected);
  }

  removeCategory(select: boolean, filter: PublicationFilter) {
    if (!select) {
      filter.selected = false;
      this.publicationFiltersSelected = this.publicationFiltersSelected.filter(
        (filterId) => filterId !== filter.filter_id
      );
    }

    this.publicationFiltersSelected = removeArrayDuplicates(this.publicationFiltersSelected);
  }

  onCloseIconClick() {
    this.closeModal(false);
  }

  private closeModal(saveChanges: boolean) {
    this.modalManager.dismissMatModal({
      saveChanges,
      filterIds: this.publicationFiltersSelected,
      initialFilterIds: this.initialFilterIds,
      allSelected: this.selectAllFilter.selected
    } as PublicationFilterResponse);
  }

  private saveFilters() {
    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK, {
      [ActionsAnalytics.CLICKACTION]: PagesAnalytics.SEE_RESULTS
    });

    const analyticsFilter = {};
    this.publicationFiltersSelected.forEach((filterId) => {
      analyticsFilter[`${PagesAnalytics.FILTER_SELECTED}_${filterId}`] = PagesAnalytics.FILTER_ON;
    });
    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK, {
      [ActionsAnalytics.CLICKACTION]: PagesAnalytics.FILTER_SELECTED,
      ...analyticsFilter
    });

    this.closeModal(true);
  }

  trackByItems(item: MSafeAny) {
    return item.title;
  }

  private deleteFilters(deleteSelectAll = true) {
    this.analyticsService.sendEvent(CategoriesAnalytics.CLICK, {
      [ActionsAnalytics.CLICKACTION]: PagesAnalytics.DELETE_FILTERS
    });
    if (deleteSelectAll) {
      this.selectAllFilter.selected = false;
    }
    this.selectedFilterIds = [];
    this.publicationFiltersSelected = [];
    this.mapCheckboxes(true);
    this.numResults$.next(0);
  }

  private getInitialResults(): number {
    let initial = 0;

    if (this.publicationFilters) {
      this.publicationFilters.forEach((filter) => {
        if (filter.selected) {
          initial += filter.result_number;
        }

        filter.subfilters?.forEach((subfilter) => {
          if (subfilter.selected) {
            initial += subfilter.result_number;
          }
        });
      });
    }

    return initial;
  }

  private setNumResults(results: number) {
    this.numResults$.next(this.numResults$.getValue() + results);
  }

  private setButtons(results: number) {
    let primaryText = '';

    if (results > 1) {
      primaryText = this.translateService.instant('PUBLICATIONS_LIST.SEE_RESULTS', { numResults: results });
    } else if (results === 1) {
      primaryText = this.translateService.instant('PUBLICATIONS_LIST.SEE_SINGLE_RESULT');
    } else {
      primaryText = this.translateService.instant('PUBLICATIONS_LIST.SEE_RESULTS', { numResults: '' });
    }

    this.buttons = [
      {
        text: 'PUBLICATIONS_LIST.DELETE_FILTERS',
        type: 'secondary',
        enabled: true,
        onClick: () => this.deleteFilters()
      },
      {
        text: primaryText,
        type: 'primary',
        enabled: true,
        onClick: () => this.saveFilters()
      }
    ];
  }

  private setSelectAllText() {
    this.selectAllFilter.filter_name = this.translateService.instant('PUBLICATIONS_LIST.SELECT_ALL');
  }
}
