import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ModalManager } from '@app/services';
import { DateDifference, RelativeDate } from '@app/shared/helpers/date-difference/date-difference.helper';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { ConfirmationModalComponent } from '@components/modals/confirmation-modal/confirmation-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { Buttons } from '@shared/models/buttons/buttons';
/* eslint-disable @typescript-eslint/naming-convention */

export class CommentHelper {
  static convertDateToRelative(commentCreationDate: string, translateService: TranslateService): Observable<MSafeAny> {
    const currentDate = new Date();
    const relativeDate: RelativeDate = DateDifference.timeDifference(commentCreationDate, currentDate);
    return translateService.get(`COMMENTS.${relativeDate.timeStamp}`, { timePast: relativeDate.timePast });
  }

  static confirmDeleteComment(modalManager: ModalManager) {
    const title = 'COMMENTS.URL_NOT_PERMITED_TITLE';
    const contents = ['COMMENTS.URL_NOT_PERMITED_DESCRIPTION'];

    const buttons: Buttons[] = [
      {
        text: 'COMMENTS.ACCEPT',
        type: 'primary',
        enabled: true,
        onClick: () => modalManager.dismissMatModal()
      }
    ];

    const modalOpts: MatDialogConfig = {
      data: { title, contents, buttons },
      disableClose: true,
      panelClass: ['media-content-modal', 'confirmation-modal']
    };

    modalManager.openMatModal(ConfirmationModalComponent, modalOpts);
  }
}
