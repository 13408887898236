import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { PagesAnalytics } from '@app/services/analytics/models/analytics.enum';
import { STORAGE_CONSTANTS } from '@app/services/storage';
import { StorageService } from '@app/services/storage/storage.service';
import { NOTIFICATION_CATEGORIES, NotificationsTabs } from '@app/shared/enums/notification/notification.enum';
import { NavTab } from '@app/shared/interfaces/nav-tab/nav-tab.interface';
import { Notification } from '@app/shared/models/notification/notification.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { NotificationService } from '@services/notification/notification.service';
import { isTablet } from '@shared/utils/is-tablet.util';
import { isMobile } from '@shared/utils/platform';

import { NOTIFICATIONS_NAV_TABS } from '../../notifications.constants';

@Component({
  selector: 'app-notification-filter',
  templateUrl: './notification-filter.component.html',
  styleUrls: ['./notification-filter.component.scss']
})
export class NotificationFilterComponent implements OnChanges {
  @Input() notificationsList!: Notification[];
  @Output() tabSelected = new EventEmitter();

  notificationCategories: MSafeAny;
  readonly pageTabs = NotificationsTabs;
  readonly pageName = PagesAnalytics.NOTIFICATIONS;
  readonly navTabs = NOTIFICATIONS_NAV_TABS;
  readonly tabStorageKey = STORAGE_CONSTANTS.NOTIFICATIONS_CURRENT_TAB;
  readonly tabpageNames = {
    [NotificationsTabs.PUBLICATIONS]: 'publicaciones',
    [NotificationsTabs.INDIVIDUAL]: 'individuales'
  };

  selectedTab!: string;
  navTabsToLoad!: NavTab[];
  unreadNotifications = [];
  pageAnalytics!: string;

  constructor(private storage: StorageService, private notificationService: NotificationService) {}

  get isMobile() {
    return isMobile() && !isTablet();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!changes.notificationsList?.currentValue?.length) {
      return;
    }

    this.determineTypesOfNotifications();
    this.getUnreadNotificationsByTabs();
    this.preselectTab();
  }

  getUnreadNotificationsByTabs() {
    this.unreadNotifications = [];
    for (const tab of this.navTabsToLoad) {
      this.unreadNotifications.push(this.unreadNotificationsNumber(tab.tabID) as never);
    }
  }

  /**
   * This method is used to between sections when select tabs
   */
  onSelectTab(tabId: string) {
    this.selectTab(tabId);
  }

  determineTypesOfNotifications() {
    this.notificationCategories = this.notificationsList
      .map((item) => item.category)
      .filter((value, index, self) => self.indexOf(value) === index);

    // eslint-disable-next-line
    this.navTabsToLoad = this.navTabs.filter((navTab) => {
      const selectedTabCategory = NOTIFICATION_CATEGORIES.indexOf(NotificationsTabs[navTab.tabID]);
      return this.notificationCategories.indexOf(selectedTabCategory) >= 0;
    });
  }

  /**
   * This method is used preselect the last tab
   * used by the user.If not, selects the first allowed tab.
   */

  getUnreadNotifications(tab?: string) {
    const category = NOTIFICATION_CATEGORIES.indexOf(NotificationsTabs[tab ?? this.selectedTab]);
    return this.notificationService.getUnreadNotifications(category);
  }

  unreadNotificationsNumber(tab?: string) {
    return this.getUnreadNotifications(tab).length;
  }

  async preselectTab() {
    const tab: string = await this.storage.get(this.tabStorageKey);

    if (tab && this.tabExists(tab)) {
      this.selectTab(tab);
      return;
    }

    let defaultTab: string = this.navTabsToLoad[0].tabID;
    const isAvailablePublications = this.navTabsToLoad.find((navTab) => {
      return navTab.tabID === NotificationsTabs.PUBLICATIONS;
    });

    if (this.navTabsToLoad.length > 1 && isAvailablePublications) {
      defaultTab = NotificationsTabs.PUBLICATIONS;
    }

    this.selectTab(defaultTab);
  }

  /**
   * This method is used to select a tab, backup the selection
   * and register a analytics event.
   * @param selectedTab Tab identifier
   */
  selectTab(selectedTab: string) {
    this.selectedTab = selectedTab;
    this.tabSelected.emit({
      selectedTab: this.selectedTab,
      unreadNotifications: this.unreadNotificationsNumber(this.selectedTab)
    });
    this.storage.setItems([{ key: this.tabStorageKey, value: this.selectedTab }]);
  }

  trackByItems(item: NavTab) {
    return item;
  }

  /**
   * This method is used to get if a tab is present.
   * @return boolean true if exist or false if not.
   */
  private tabExists(tab: string): boolean {
    const notificationsNavTab = this.navTabsToLoad.find((navTab) => navTab.tabID === tab);
    return Boolean(tab in this.pageTabs && notificationsNavTab && !notificationsNavTab.hidden);
  }
}
